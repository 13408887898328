var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6ejMw-7v3JsKxkBXWcwjE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://d517509ca78940888396bee82b2db48a@o420741.ingest.sentry.io/5832102',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: ['Network request failed', 'Failed to fetch', 'Network Error', 'AbortError: Share canceled']
  // beforeSend(event, hint) {
  //   if (hint.originalException === "Timeout") return null;
  //   return event;
  // }
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // ignoreErrors: ['Network request failed', 'Failed to fetch', 'Network Error', 'withrealtime/messaging']
});
