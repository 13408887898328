import React from 'react'

import AcceptedIcon from '@public/icons/accepted-icon.svg'
import ActiveleaseIcon from '@public/icons/activelease-icon.svg'
import AnnotationInfoIcon from '@public/icons/annotation-info-icon.svg'
import AppleIcon from '@public/icons/apple-icon.svg'
import AreaIcon from '@public/icons/area-icon.svg'
import AvataroutlineIcon from '@public/icons/avataroutline-icon.svg'
import BankNoteIcon from '@public/icons/bank-note-icon.svg'
import BedIcon from '@public/icons/bed-icon.svg'
import BedRoomIcon from '@public/icons/bed-room-icon.svg'
import BedBugIcon from '@public/icons/bedbug-icon.svg'
import BellRingingIcon from '@public/icons/bell-ringing-icon.svg'
import BlubIcon from '@public/icons/blub-icon.svg'
import BuildingAltIcon from '@public/icons/building-1-icon.svg'
import Building2Icon from '@public/icons/building-2-icon.svg'
import BuildingIcon from '@public/icons/building-icon.svg'
import BuildingsIcon from '@public/icons/buildings-icon.svg'
import ChatQuestionIcon from '@public/icons/chat-ques-icon.svg'
import CheckCircle from '@public/icons/check-circle-icon.svg'
import CheckVerifiedIcon from '@public/icons/check-verified-icon.svg'
import CommentIcon from '@public/icons/comment-icon.svg'
import CreditCardIcon from '@public/icons/credit-card-icon.svg'
import CrossIcon from '@public/icons/cross-icon.svg'
import DiscoverStarIcon from '@public/icons/discover-star-icon.svg'
import DollarMoneyIcon from '@public/icons/dollar-money-icon.svg'
import DownExpandIcon from '@public/icons/down-expand-icon.svg'
import DownTrendIcon from '@public/icons/down-trend-icon.svg'
import DownArrowIcon from '@public/icons/downArrow-icon.svg'
import EditIcon from '@public/icons/edit-icon.svg'
import EmailIcon from '@public/icons/email-icon.svg'
import EyeIcon from '@public/icons/eye-icon.svg'
import FileAttachIcon from '@public/icons/file-attachment-icon.svg'
import FileQuestionIcon from '@public/icons/file-question-icon.svg'
import FileSearchIcon from '@public/icons/file-search-icon.svg'
import FlagIcon from '@public/icons/flag-icon.svg'
import GiftIcon from '@public/icons/gift-icon.svg'
import GoogleIcon from '@public/icons/google-icon.svg'
import GreenGraphIcon from '@public/icons/green-graph-icon.svg'
import HalfCheckUserIcon from '@public/icons/halfcheck-user-icon.svg'
import HeartIcon from '@public/icons/heart-icon.svg'
import HomeIcon from '@public/icons/home-icon.svg'
import HomeSmileIcon from '@public/icons/home-smile-icon.svg'
import ImageIcon from '@public/icons/image-icon.svg'
import InfoIcon from '@public/icons/info-icon.svg'
import InreviewIcon from '@public/icons/inreview-icon.svg'
import LatestIcon from '@public/icons/latest-icon.svg'
import ListViewIcon from '@public/icons/list-view-icon.svg'
import ListingIcon from '@public/icons/listing-icon.svg'
import LocateIcon from '@public/icons/locate-icon.svg'
import LocationIcon from '@public/icons/location-icon.svg'
import LockIcon from '@public/icons/lock-icon.svg'
import LogoutIcon from '@public/icons/logout-icon.svg'
import LongArrowDownIcon from '@public/icons/long-arrow-down-icon.svg'
import LongArrowRightIcon from '@public/icons/long-arrow-right-icon.svg'
import MapIcon from '@public/icons/map-icon.svg'
import NewBuildingIcon from '@public/icons/new-building-icon.svg'
import NoBedBugIcon from '@public/icons/no-bedbug-icon.svg'
import NoFoundIcon from '@public/icons/no-found-icon.svg'
import PayrentIcon from '@public/icons/payrent-icon.svg'
import PencilLineIcon from '@public/icons/pencil-line-icon.svg'
import PendingIcon from '@public/icons/pending-icon.svg'
import PinMarkerIcon from '@public/icons/pin-marker-icon.svg'
import ProfileIcon from '@public/icons/profile-icon.svg'
import RedGraphIcon from '@public/icons/red-graph-icon.svg'
import RejectedIcon from '@public/icons/rejected-icon.svg'
import RentinvoicesIcon from '@public/icons/rentinvoices-icon.svg'
import ReviewIcon from '@public/icons/review-icon.svg'
import SearchGlassIcon from '@public/icons/search-glass-icon.svg'
import SearchIcon from '@public/icons/search-icon.svg'
import SearchLocationIcon from '@public/icons/search-location-icon.svg'
import SettingsIcon from '@public/icons/settings-icon.svg'
import ShareIcon from '@public/icons/share-icon.svg'
import SharePurpleBoldIcon from '@public/icons/share-purple-bold-icon.svg'
import SharePurpleIcon from '@public/icons/share-purple-icon.svg'
import ShowerIcon from '@public/icons/shower-icon.svg'
import SortIcon from '@public/icons/sort-icon.svg'
import SparkleIcon from '@public/icons/sparkle-icon.svg'
import SpeakerIcon from '@public/icons/speaker-icon.svg'
import SquareplusIcon from '@public/icons/squareplus-icon.svg'
import StarIcon from '@public/icons/star-icon.svg'
import StarOutlineIcon from '@public/icons/star-outline-icon.svg'
import SupportIcon from '@public/icons/support-icon.svg'
import ThumbsDownIcon from '@public/icons/thumbs-down-icon.svg'
import ThumbsUpIcon from '@public/icons/thumbs-up-icon.svg'
import TickIcon from '@public/icons/tick-icon.svg'
import UpCollapseIcon from '@public/icons/up-collapse-icon.svg'
import UpTrendIcon from '@public/icons/up-trend-icon.svg'
import UpArrowIcon from '@public/icons/upArrow-icon.svg'
import UpcomingrentIcon from '@public/icons/upcomingrent-icon.svg'
import UploadIcon from '@public/icons/upload-img-icon.svg'
import VideoIcon from '@public/icons/video-icon.svg'
import WarningIcon from '@public/icons/warning-icon.svg'
import WithdrawnIcon from '@public/icons/withdrawn-icon.svg'
import WriteIcon from '@public/icons/write-icon.svg'
import WriteReviewIcon from '@public/icons/write-review-icon.svg'

export const icons = {
  accepted: AcceptedIcon,
  activelease: ActiveleaseIcon,
  apple: AppleIcon,
  area: AreaIcon,
  avataroutline: AvataroutlineIcon,
  bed: BedIcon,
  blub: BlubIcon,
  building: BuildingIcon,
  comment: CommentIcon,
  cross: CrossIcon,
  creditcard: CreditCardIcon,
  eye: EyeIcon,
  google: GoogleIcon,
  heart: HeartIcon,
  image: ImageIcon,
  info: InfoIcon,
  inreview: InreviewIcon,
  listing: ListingIcon,
  payrent: PayrentIcon,
  pending: PendingIcon,
  rejected: RejectedIcon,
  rentinvoices: RentinvoicesIcon,
  review: ReviewIcon,
  search: SearchIcon,
  shower: ShowerIcon,
  squareplus: SquareplusIcon,
  star: StarIcon,
  thumbsDown: ThumbsDownIcon,
  thumbsUp: ThumbsUpIcon,
  noFound: NoFoundIcon,
  upcomingrent: UpcomingrentIcon,
  upload: UploadIcon,
  withdrawn: WithdrawnIcon,
  upArrow: UpArrowIcon,
  downArrow: DownArrowIcon,
  share: ShareIcon,
  sharePurple: SharePurpleIcon,
  sharePurpleBold: SharePurpleBoldIcon,
  searchGlass: SearchGlassIcon,
  settings: SettingsIcon,
  tick: TickIcon,
  map: MapIcon,
  sort: SortIcon,
  latest: LatestIcon,
  upTrend: UpTrendIcon,
  downTrend: DownTrendIcon,
  downExpand: DownExpandIcon,
  listView: ListViewIcon,
  searchLocation: SearchLocationIcon,
  writeReview: WriteReviewIcon,
  write: WriteIcon,
  checkCircle: CheckCircle,
  homeSmile: HomeSmileIcon,
  fileAttach: FileAttachIcon,
  fileQuestion: FileQuestionIcon,
  homeIcon: HomeIcon,
  bellRinging: BellRingingIcon,
  profile: ProfileIcon,
  email: EmailIcon,
  logout: LogoutIcon,
  edit: EditIcon,
  upCollapse: UpCollapseIcon,
  support: SupportIcon,
  lock: LockIcon,
  flag: FlagIcon,
  gift: GiftIcon,
  video: VideoIcon,
  starOutline: StarOutlineIcon,
  chatQuestion: ChatQuestionIcon,
  warning: WarningIcon,
  sparkle: SparkleIcon,
  bedbug: BedBugIcon,
  noBedbug: NoBedBugIcon,
  buildingAlt: BuildingAltIcon,
  buildings2: Building2Icon,
  buildings: BuildingsIcon,
  bankNote: BankNoteIcon,
  bedroom: BedRoomIcon,
  pinMarker: PinMarkerIcon,
  annotationInfo: AnnotationInfoIcon,
  redGraph: RedGraphIcon,
  greenGraph: GreenGraphIcon,
  dollarMoney: DollarMoneyIcon,
  speaker: SpeakerIcon,
  halfCheckUser: HalfCheckUserIcon,
  newBuilding: NewBuildingIcon,
  longArrowDown: LongArrowDownIcon,
  longArrowRight: LongArrowRightIcon,
  pencilLine: PencilLineIcon,
  checkVerified: CheckVerifiedIcon,
  discoverStar: DiscoverStarIcon,
  fileSearch: FileSearchIcon,
  location: LocationIcon,
  locate: LocateIcon,
}

export type OIIconProps = {
  name: keyof typeof icons
  stroke?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'custom'
  height?: number
  width?: number
  [key: string]: any
}

export const standardIconSize = {
  sm: 8,
  md: 14,
  lg: 16,
  xl: 24,
  custom: 'custom',
}

export default function OIIcon({
  name,
  stroke = 'none',
  size = 'lg',
  width = 16,
  height = 16,
  ...rest
}: OIIconProps) {
  const SVGIcon = icons[name]

  const SVGIconComp = (p) => (
    <SVGIcon
      stroke={stroke}
      width={size == 'custom' ? width : standardIconSize[size]}
      height={size == 'custom' ? height : standardIconSize[size]}
      {...p}
    />
  )
  return <SVGIconComp {...rest} />
}
