import { getExplorerData } from 'src/data/RentalReport'
import { getNewContactedUnits, getNewSavedUnits } from 'src/data/newUserProfile'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const setCurrentUserSavedUnits = createAsyncThunk(
  'currentUser/setCurrentUserSavedUnits',
  async (data: { token: string }) => {
    const { token } = data
    return await getNewSavedUnits(token)
  }
)

export const setCurrentUserContactedUnits = createAsyncThunk(
  'currentUser/setCurrentUserContactedUnits',
  async (data: { token: string }) => {
    const { token } = data
    return await getNewContactedUnits(token)
  }
)

export const setCurrentUserExplorerData = createAsyncThunk(
  'currentUser/setCurrentUserExplorerData',
  async (data: { token: string }) => {
    const { token } = data
    return await getExplorerData(token)
  }
)
