import { HYDRATE } from 'next-redux-wrapper'
import { ActionCardItem } from 'src/data/ActionCards'

import { MiscState } from '@localtypes/Store'
import { OpenListing } from '@openigloo/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: MiscState = {
  cities: [],
  ratingTypes: [],
  topRatedBuildings: [],
  topReviewedBuildings: [],
  topRatedContacts: [],
  topReviewedContacts: [],
  recentlyReviewedBuildings: [],
  featuredResources: [],
  allCityResources: [],
  neighborhoods: [],
  boroughs: [],
  availableListings: [],
  boroughBuildings: null,
  recentlyAddedListings: [],
  topRatedListings: [],
  actionCardItems: [],
  subscriptionPrices: null,
}

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setCities(state, action: PayloadAction<any[]>) {
      state.cities = action.payload
    },
    setRatingTypes: (state, action) => {
      state.ratingTypes = action.payload
    },
    setTopRatedBuildings: (state, action) => {
      state.topRatedBuildings = action.payload
    },
    setTopReviewedBuildings: (state, action) => {
      state.topReviewedBuildings = action.payload
    },
    setTopRatedContacts: (state, action) => {
      state.topRatedContacts = action.payload
    },
    setTopReviewedContacts: (state, action) => {
      state.topReviewedContacts = action.payload
    },
    setRecentlyReviewedBuildings: (state, action) => {
      state.recentlyReviewedBuildings = action.payload
    },
    setFeaturedResources: (state, action) => {
      state.featuredResources = action.payload
    },
    setAllCityResources: (state, action) => {
      state.allCityResources = action.payload
    },
    setNeighborhoods: (state, action) => {
      state.neighborhoods = action.payload
    },
    setBoroughs: (state, action) => {
      state.boroughs = action.payload
    },
    setAvailableListings: (state, action: PayloadAction<OpenListing[]>) => {
      state.availableListings = action.payload
    },
    setBoroughBuildings: (state, action) => {
      state.boroughBuildings = action.payload
    },
    setRecentlyAddedListings: (state, action: PayloadAction<OpenListing[]>) => {
      state.recentlyAddedListings = action.payload
    },
    setTopRatedListings: (state, action: PayloadAction<OpenListing[]>) => {
      state.topRatedListings = action.payload
    },
    setActionCardItems: (state, action: PayloadAction<ActionCardItem[]>) => {
      state.actionCardItems = action.payload
    },
    setSubscriptionPrices: (state, action) => {
      state.subscriptionPrices = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.misc,
        subscriptionPrices: state.subscriptionPrices || action.payload.misc.subscriptionPrices,
      }
    })
  },
})

export default miscSlice.reducer
