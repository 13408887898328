import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { createPortal } from 'react-dom'

import { GrClose } from '@react-icons/all-files/gr/GrClose'

import { OIModalProps } from './interface'

export const OIModal = ({
  title,
  useStandardHeader = true,
  customHeader,
  onClose,
  subtitle,
  children,
  customModalStyle = '',
  closeOnEscape = true,
  closeOnOutsideClick = true,
  showHeader = true,
  customHeight = true,
}: OIModalProps) => {
  const [isModalClosing, setIsModalClosing] = useState(false)
  const [height, setHeight] = useState(0)
  const [, setHeaderHeight] = useState(0)
  const headerRef = useRef(null)

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)
    window.addEventListener('resize', handleResize)
    setHeight(window.innerHeight)
    setHeaderHeight(headerRef?.current?.clientHeight)

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
      window.removeEventListener('resize', handleResize)
      document.body.style.position = 'static'
    }
  }, [])

  const handleResize = () => {
    setHeight(window.innerHeight)
    setHeaderHeight(headerRef?.current?.clientHeight)
  }

  const handleClose = () => {
    setIsModalClosing(true)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  const handleClickClose = (e) => {
    if (!closeOnOutsideClick) return
    if (e.target.id && e.target.id == 'background') onClose()
  }

  const handleEscape = (e) => {
    if (!closeOnEscape) return
    if (e.key === 'Escape') onClose()
  }

  const CloseButton = () => (
    <div onClick={handleClose} className="hover:cursor-pointer">
      <GrClose />
    </div>
  )

  const StandardHeader = () => (
    <div className="flex w-full items-baseline justify-between space-x-4 bg-light-10 px-8 py-6 text-xl font-semibold text-dark-900 md:justify-start">
      <div className="flex-1 flex flex-col">
        <span className=" text-center md:text-left">{title}</span>
        {subtitle && (
          <span className=" pt-2 text-center text-base text-dark-500 md:text-left">{subtitle}</span>
        )}
      </div>
      <GrClose className="cursor-pointer text-bluegray-300" size={16} onClick={handleClose} />
    </div>
  )

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableBodyScroll = () => {
    document.body.style.overflow = ''
  }

  useEffect(() => {
    disableBodyScroll()
    return () => {
      enableBodyScroll()
    }
  }, [])

  return createPortal(
    <div
      className="fixed top-0 bottom-0 left-0 z-[100] !m-0 h-screen w-full bg-black bg-opacity-80 text-black md:py-8"
      id="background"
      onClick={handleClickClose}
      onKeyPress={handleEscape}
    >
      <div
        className={`slide-up relative mx-auto flex max-w-[809px] flex-col overflow-hidden bg-white sm:rounded-t-3xl md:max-w-[860px] md:rounded-3xl lg:max-w-[896px] xl:max-w-screen-lg ${
          isModalClosing ? 'slide-down' : ''
        }
        overflow-auto md:max-h-[calc(100vh_-_64px)] ${customModalStyle}`}
        ref={headerRef}
      >
        {showHeader && (
          <div>
            {customHeader ? (
              customHeader
            ) : useStandardHeader ? (
              <StandardHeader />
            ) : (
              <div className="absolute top-8 right-8 z-10">
                <CloseButton />
              </div>
            )}
          </div>
        )}
        {isMobile && customHeight ? (
          <div style={{ height: `${height - 76}px` }}>{children}</div>
        ) : (
          <div className="flex flex-grow flex-col overflow-hidden">{children}</div>
        )}
      </div>
    </div>,
    document.body
  )
}

export default OIModal
export * from './interface'
