import React, { InputHTMLAttributes } from 'react'

import OIIcon, { icons } from '../../icons'

export interface OIInputProps extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  icon?: keyof typeof icons | ''
  iconPositionLeft?: boolean
  iconSize?: 'sm' | 'md' | 'lg'
  multipleLines?: boolean
  invalid?: boolean
  containerStyle?: string
  onlyIntegerInput?: boolean
  inputIcon?: JSX.Element
  inputIconCss?: string
}

const TEXT_STYLES = 'text-primarytext text-sm md:text-base'
const DISABLED_STYLES = 'bg-shadow text-secondarytext'

export const OIInput = ({
  icon,
  iconPositionLeft = true,
  iconSize = 'lg',
  multipleLines = false,
  invalid = false,
  containerStyle = '',
  onlyIntegerInput = false,
  inputIcon,
  inputIconCss = '',
  ...rest
}: OIInputProps) => {
  const change = (e) => {
    if (onlyIntegerInput) {
      const regex = /^[0-9]\d*$/
      const value = e.target.value
      const matches = regex.test(value)
      if (!value || matches) {
        rest.onChange(e)
      }
    } else if (rest.onChange) {
      rest.onChange(e)
    }
  }

  return multipleLines ? (
    <textarea
      className={`rounded-lg border-border bg-transparent bg-white py-2 px-4 text-sm outline-none md:text-base ${containerStyle} ${
        rest.disabled ? DISABLED_STYLES : ''
      }`}
      {...rest}
    />
  ) : (
    <div className={`application-input ${TEXT_STYLES} ${containerStyle} relative`}>
      {icon && (
        <span
          className={`inline-flex items-center ${iconPositionLeft ? 'order-1 pr-2' : 'order-2'}`}
        >
          {<OIIcon name={icon} size={iconSize} />}
        </span>
      )}
      {inputIcon && <div className={`absolute top-3.5 left-2 ${inputIconCss}`}>{inputIcon}</div>}
      <input
        {...rest}
        autoComplete="new-password"
        name={`input_${Math.random()}`}
        className={`${
          iconPositionLeft ? 'order-2' : 'order-1 pr-2'
        } !appearance-none !text-dark-900 ${rest.className} ${invalid ? '!border-red-400' : ''}`}
        onChange={change}
      />
    </div>
  )
}

export default OIInput
