import getConfig from 'next/config'

import { apiFetch } from './Api'

// New Profile Token Flow

const {
  publicRuntimeConfig: { BACKEND_API_PROFILE },
} = getConfig()

const baseProfileURL = BACKEND_API_PROFILE

export async function getCustomLoginToken(token: string) {
  const response = await apiFetch({
    path: `/profile/get_custom_token/`,
    data: {
      token: token,
    },
    method: 'post',
    baseURL: baseProfileURL,
    noOrigin: true,
  })
  return response
}
