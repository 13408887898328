import { HYDRATE } from 'next-redux-wrapper'

import { ListingState } from '@localtypes/Store'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ListingState = {
  listing: null,
  similarListings: [],
  cityAmenities: [],
  medianRent: null,
  listingInterestBody: {
    numOccupants: null,
    hasPets: null,
    petType: [],
    moveInDate: null,
    message: '',
    householdIncome: null,
    creditScoreRange: null,
    phone: null,
  },
}

export const listingSlice = createSlice({
  name: 'currentListing',
  initialState,
  reducers: {
    setListing: (state, action) => {
      state.listing = action.payload
    },
    setListingInterestBody(state, action) {
      state.listingInterestBody = action.payload
    },
    clearLastListingInterestBody(state) {
      state.listingInterestBody = { ...initialState.listingInterestBody }
    },
    setMedianRent: (state, action) => {
      state.medianRent = action.payload
    },
    setSimilarListings: (state, action) => {
      state.similarListings = action.payload
    },
    setCityAmenities(state, action) {
      state.cityAmenities = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.currentListing,
        listingInterestBody: state.listingInterestBody,
      }
    })
  },
})

export default listingSlice.reducer
