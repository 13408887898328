import { icons } from '../../../icons'

export interface OIButtonProps {
  type?: OIButtonType
  status?: OIButtonStatus | ''
  size?: OIButtonSize
  noBackground?: boolean
  label?: string
  disabled?: boolean
  onClick?: () => void
  icon?: keyof typeof icons | ''
  iconSize?: OIButtonIconSize
  iconPositionLeft?: boolean
  iconColor?: string
  style?: string
  labelStyle?: string
  isLoading?: boolean
  loaderColor?: string
  loaderSize?: number
}

export enum OIButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum OIButtonStatus {
  SUCCESSFUL = 'successful',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum OIButtonSize {
  STANDARD = 'standard',
  LARGE = 'large',
}

export enum OIButtonIconSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}
