import { HYDRATE } from 'next-redux-wrapper'
import { ReviewApi } from 'src/common/types'

import { ContactState } from '@localtypes/Store'
import {
  Building,
  BuildingViolations,
  BuildingViolationsEvictions,
  BuildingViolationsLitigations,
  Contact,
  ContactAggResponse,
  ContactOverview,
  OpenListing,
  Review,
} from '@openigloo/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: ContactState = {
  contact: null,
  buildings: [],
  reviews: [],
  listings: [],
  limitedReviews: [],
  overview: null,
  contactViolations: null,
  contactEvictions: null,
  contactLitigations: null,
  reviewCount: null,
}

export const contactSlice = createSlice({
  name: 'currentContact',
  initialState,
  reducers: {
    setContact(state, action: PayloadAction<Contact>) {
      state.contact = action.payload
    },
    setReviews(state, action: PayloadAction<ReviewApi[]>) {
      state.reviews = action.payload
    },
    setReviewsCount(state, action: PayloadAction<number>) {
      state.reviewCount = action.payload
    },
    setReviewsPaginated(state, action: PayloadAction<ReviewApi[]>) {
      state.reviews = [...state.reviews, ...action.payload]
    },
    setLimitedReviews(state, action: PayloadAction<Review[]>) {
      state.limitedReviews = action.payload
    },
    setListings(state, action: PayloadAction<OpenListing[]>) {
      state.listings = action.payload
    },
    setBuildings(state, action: PayloadAction<Building[]>) {
      state.buildings = action.payload
    },
    setOverview(state, action: PayloadAction<ContactOverview>) {
      state.overview = action.payload
    },
    setAggResponse(state, action: PayloadAction<ContactAggResponse>) {
      state.aggResponses = action.payload
    },
    setContactViolations(state, action: PayloadAction<BuildingViolations>) {
      state.contactViolations = action.payload
    },
    setContactEvictions(state, action: PayloadAction<BuildingViolationsEvictions>) {
      state.contactEvictions = action.payload
    },
    setContactLitigations(state, action: PayloadAction<BuildingViolationsLitigations>) {
      state.contactLitigations = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.currentContact,
      }
    },
  },
})

export default contactSlice.reducer
