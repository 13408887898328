import { BuildingApi } from 'src/common/types'

import { Buildings } from '../../data'
import { buildingSlice } from '../reducers/building'

export const resetBuildingState = () => async (dispatch) => {
  dispatch(buildingSlice.actions.reset())
}

export const setCurrentBuildingOtherApi =
  (token: string, building: BuildingApi, hasReviewerAccess: boolean) => async (dispatch) => {
    if (hasReviewerAccess) {
      const responses = await Buildings.getNewBuildingResponses(building.slug, token)
      dispatch(buildingSlice.actions.setAggResponses(responses))
    }
  }
