import { UnitApi } from 'src/common/types'

import { AuthPrompts } from '@components/auth/AuthPrompts'
import { UserState, newProfile } from '@localtypes/Store'
import {
  Lease,
  NYC_LAT,
  NYC_LNG,
  PaymentMethod,
  ProductBuilding,
  ProductContact,
  Resource,
  Review,
  Tenancy,
  UserPlaidAccount,
} from '@openigloo/common'
import {
  setCurrentUserContactedUnits,
  setCurrentUserExplorerData,
  setCurrentUserSavedUnits,
} from '@redux/thunks/user'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: UserState = {
  profile: null,
  token: '',
  isLoggedIn: null,
  savedBuildings: [],
  savedContacts: [],
  savedUnits: [],
  savedResources: [],
  tenancies: [],
  helpfulReviews: [],
  authRequired: { loginRequired: false, emailPrefill: '', onDismissURL: null, prompt: 'default' },
  plaidAccounts: [],
  paymentMethods: [],
  contactedUnits: [],
  savedFilters: [],
  tenantApplications: [],
  rentPayments: [],
  leases: [],
  subscriptionLink: null,
  unitApplications: [],
  suggestedActions: [],
  loadingData: {
    userProfile: false,
    unitApplications: false,
    savedUnits: false,
    contactedUnits: true,
    explorerData: true,
  },
  referralData: null,
  currentCity: {
    id: 'nyc',
    name: 'New York City',
    center: { latitude: NYC_LAT, longitude: NYC_LNG },
  },
  reviewerData: null,
  reviews: [],
  explorerData: null,
  consentEnabled: false,
  triggerConsent: false,
  newInterestPhone: null,
}

export const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    reset(state) {
      return { ...initialState, currentCity: state.currentCity }
    },
    setUserProfile(state, action: PayloadAction<newProfile>) {
      state.profile = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
    setSubscriptionLink(state, action) {
      state.subscriptionLink = action.payload
    },
    setSavedBuildings(state, action: PayloadAction<ProductBuilding[]>) {
      state.savedBuildings = action.payload
    },
    addSavedBuilding(state, action: PayloadAction<ProductBuilding>) {
      state.savedBuildings.push(action.payload)
    },
    removeSavedBuilding(state, action: PayloadAction<ProductBuilding>) {
      state.savedBuildings = state.savedBuildings.filter((b) => b.id !== action.payload.id)
    },
    setSavedContacts(state, action: PayloadAction<ProductContact[]>) {
      state.savedContacts = action.payload
    },
    addSavedContact(state, action: PayloadAction<ProductContact>) {
      state.savedContacts.push(action.payload)
    },
    removeSavedContact(state, action: PayloadAction<ProductContact>) {
      state.savedContacts = state.savedContacts.filter((c) => c.id !== action.payload.id)
    },
    addSavedUnit(state, action: PayloadAction<UnitApi>) {
      state.savedUnits.push(action.payload)
    },
    removeSavedUnit(state, action: PayloadAction<UnitApi>) {
      state.savedUnits = state.savedUnits.filter((l) => l.id !== action.payload.id)
    },
    setTenancies(state, action: PayloadAction<Tenancy[]>) {
      state.tenancies = action.payload
    },
    updateTenancies(state, action: PayloadAction<{ tenancy: Tenancy; id: string }>) {
      const x = state.tenancies.findIndex((e) => e.id === action.payload.id)
      state.tenancies[x] = action.payload.tenancy
    },
    setLeases(state, action: PayloadAction<Lease[]>) {
      state.leases = action.payload
    },
    addTenancy(state, action: PayloadAction<Tenancy>) {
      if (!state.tenancies.map((t) => t.id).includes(action.payload.id))
        state.tenancies.unshift(action.payload)
    },
    deleteTenancy(state, action: PayloadAction<string>) {
      state.tenancies = state.tenancies.filter((t) => t.id !== action.payload)
    },
    addContactedUnits(state, action) {
      state.contactedUnits.push(action.payload)
    },
    setAuthRequired(
      state,
      action: PayloadAction<{
        loginRequired: boolean
        emailPrefill: string
        onDismissURL: string
        prompt: keyof typeof AuthPrompts
        phonePrefill: string
      }>
    ) {
      state.authRequired = action.payload
    },
    updateTenancyReview(
      state,
      action: PayloadAction<{ tenancyId: string; field: string; value: any }>
    ) {
      const tenancy = state.tenancies.find((t) => t.id === action.payload.tenancyId)
      tenancy.review[action.payload.field] = action.payload.value
    },
    setHelpfulReviews(state, action: PayloadAction<Review[]>) {
      state.helpfulReviews = action.payload
    },
    addHelpfulReview(state, action: PayloadAction<Review>) {
      const isFound = state.helpfulReviews.find((r) => r.id === action.payload.id)
      if (!isFound) {
        state.helpfulReviews.push(action.payload)
      }
    },
    removeHelpfulReview(state, action: PayloadAction<Review>) {
      state.helpfulReviews = state.helpfulReviews.filter((r) => r.id !== action.payload.id)
    },
    setPlaidAccounts(state, action: PayloadAction<UserPlaidAccount[]>) {
      state.plaidAccounts = action.payload
    },
    setPaymentMethods(state, action: PayloadAction<PaymentMethod[]>) {
      state.paymentMethods = action.payload
    },
    setSavedResources(state, action: PayloadAction<Resource[]>) {
      state.savedResources = action.payload
    },
    addSavedResource(state, action: PayloadAction<Resource>) {
      state.savedResources.push(action.payload)
    },
    removeSavedResource(state, action: PayloadAction<Resource>) {
      state.savedResources = state.savedResources.filter((r) => r.id !== action.payload.id)
    },
    setSavedFilters(state, action: PayloadAction<any>) {
      state.savedFilters = action.payload
    },
    addSavedFilter(state, action: PayloadAction<any>) {
      state.savedFilters.push(action.payload)
    },
    setUnitApplications(state, action) {
      state.unitApplications = action.payload
    },
    setCurrentCity(state, action: PayloadAction<any>) {
      state.currentCity = action.payload
    },
    setReviewerData(state, action: PayloadAction<any>) {
      state.reviewerData = action.payload
    },
    setReviewData(state, action: PayloadAction<any>) {
      state.reviews = action.payload
    },
    setExplorerData(state, action: PayloadAction<any>) {
      state.explorerData = action.payload
    },
    setConsentEnabled(state, action: PayloadAction<boolean>) {
      state.consentEnabled = action.payload
    },
    setTriggerConsent(state, action: PayloadAction<boolean>) {
      state.triggerConsent = action.payload
    },
    setNewInterestPhone(state, action: PayloadAction<any>) {
      state.newInterestPhone = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUserSavedUnits.pending, (state) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            savedUnits: true,
          },
        }
      })
      .addCase(setCurrentUserSavedUnits.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            savedUnits: false,
          },
          savedUnits: action.payload,
        }
      })
      .addCase(setCurrentUserContactedUnits.pending, (state) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            contactedUnits: true,
          },
        }
      })
      .addCase(setCurrentUserContactedUnits.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            contactedUnits: false,
          },
          contactedUnits: action.payload,
        }
      })
      .addCase(setCurrentUserExplorerData.pending, (state) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            explorerData: true,
          },
        }
      })
      .addCase(setCurrentUserExplorerData.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingData: {
            ...state.loadingData,
            explorerData: false,
          },
          explorerData: action.payload,
        }
      })
  },
})

export default userSlice.reducer
