import getConfig from 'next/config'
import { BuildingApi, ContactApi, ReviewApi } from 'src/common/types'

import { ContactAggResponse, OpenListing } from '@openigloo/common'

import { apiFetch } from './Api'
import { getResponsePercentages } from './Buildings'

const {
  publicRuntimeConfig: { BACKEND_API_BUILDING, BACKEND_API_REVIEW },
} = getConfig()

const baseBuildingURL = BACKEND_API_BUILDING
const baseReviewURL = BACKEND_API_REVIEW

// New Contact Service

export async function getContactApi(id: string): Promise<ContactApi> {
  const data = await apiFetch({
    path: `/contact/${id}/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getContactBuildingSlugs(id: string): Promise<any> {
  const data = await apiFetch({
    path: `/contact/${id}/open_data_update_building_slugs/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getContactOpenDataApi(id: string): Promise<any> {
  const data = await apiFetch({
    path: `/contact/${id}/update_open_data/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getContactBuildingApi(
  id: string,
  page?: number
): Promise<{ results: BuildingApi[]; count: number; next: string }> {
  return await apiFetch({
    path: `/contact/${id}/buildings/?page=${page}&page_size=6`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getContactListingsApi(
  id: string,
  city: string,
  page: number
): Promise<{ results: OpenListing[]; count: number; next: string }> {
  return await apiFetch({ path: `/${city}/contacts/${id}/units/?page=${page}&page_size=6` })
}

export async function getContactRedirectApi(id: string): Promise<{ id: string; slug: string }> {
  const data = await apiFetch({
    path: `/contact/${id}/redirect_contact/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getContactsTopRated(
  pageSize: number,
  city: string
): Promise<{ results: ContactApi[] }> {
  return await apiFetch({
    path: `/contact/top_rated/new-york?page_size=${pageSize}&city=${city}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getContactsTopReviewed(
  pageSize: number,
  city: string
): Promise<{ results: ContactApi[] }> {
  return await apiFetch({
    path: `/contact/top_reviewed/new-york?page_size=${pageSize}&city=${city}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getNewContactRatings(authToken: string, contactId: string): Promise<any> {
  return await apiFetch({
    path: `/review/contact_ratings/${contactId}/`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getNewContactResponses(
  contactId: string,
  authToken?: string
): Promise<ContactAggResponse> {
  const data = (await apiFetch({
    path: `/review/contact_responses/${contactId}/`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })) as ContactAggResponse

  return getResponsePercentages(data) as ContactAggResponse
}

export async function getContactBuildingReviewsPaginated(
  contactId: string,
  authToken: string,
  page: number
): Promise<{ results: ReviewApi[]; next: string; count: number }> {
  return await apiFetch({
    path: `/review/contact_reviews/${contactId}/?page=${page}&page_size=5`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getWorstLandlords(year: string): Promise<{ results: ContactApi[] }> {
  return await apiFetch({
    path: `/contact/worst_landlords/${year}/?page=1&page_size=100`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}
