import axios, { AxiosResponse, Method } from 'axios'
import { CacheProperties, buildWebStorage, setupCache } from 'axios-cache-interceptor'
import camelCaseKeys from 'camelcase-keys'
import getConfig from 'next/config'
import { getFirebaseAuth } from 'src/lib/firebase'

import { Suggestion } from '@openigloo/common'
import { titleCase } from '@utility/Utilities'

const {
  publicRuntimeConfig: { BACKEND_API, BACKEND_CACHED_API, BACKEND_API_BUILDING },
} = getConfig()

const baseBuildingURL = BACKEND_API_BUILDING

const fetchInstance = setupCache(
  axios.create({
    baseURL: BACKEND_API,
    timeout: 120000,
  }),
  {
    debug: console.log,
    cacheTakeover: false,
    ...(typeof window !== 'undefined'
      ? { storage: buildWebStorage(localStorage, 'axios-cache:') }
      : {}),
  }
)

fetchInstance.defaults.paramsSerializer = (par) => {
  return Object.entries(par)
    .map(
      ([key, value]) =>
        `${key}=${
          Array.isArray(value) || typeof value === 'object'
            ? JSON.stringify(value)
            : encodeURIComponent(value)
        }`
    )
    .join('&')
}

type APIFetchArgs = {
  path: string
  method?: Method
  params?: any
  headers?: any
  authToken?: string
  data?: any
  isCustomHeader?: boolean
  noAuthorization?: boolean
  cache?: false | Partial<CacheProperties>
  baseURL?: string | null
  noOrigin?: boolean
}

type PaginatedAPIFetchResults = {
  count: number
  next: string
  results: any[]
}

const isCachedPath = (_path: string) => {
  // const re = /^\/nyc\/(buildings|units)\/\d+\-.+/
  // return re.test(path)
  return false
}
const getBaseURL = (path: string, method: string, token: string) => {
  if ((method === 'get' || method === 'GET') && !token && isCachedPath(path)) {
    return BACKEND_CACHED_API
  }
  return BACKEND_API
}

export const apiFetch = async ({
  path,
  method = 'get',
  params = {},
  data = null,
  headers = {},
  authToken = null,
  isCustomHeader = false,
  noAuthorization,
  baseURL = null,
  noOrigin = false,
}: APIFetchArgs) => {
  if (authToken !== null && !isCustomHeader) {
    headers = {
      ...headers,
      Authorization:
        typeof window !== 'undefined'
          ? `token ${await getFirebaseAuth().currentUser?.getIdToken()}`
          : `token ${authToken}`,
      'x-openigloo-origin': 'web',
    }
  }
  if (noAuthorization) {
    delete headers.Authorization
  }
  if (noOrigin) {
    delete headers['x-openigloo-origin']
  }
  try {
    const response = (await fetchInstance.request({
      baseURL: baseURL ?? getBaseURL(path, method, authToken),
      method,
      url: path,
      params,
      headers,
      data,
      cache: false,
    })) as AxiosResponse<any>

    if (method === 'delete' || method === 'DELETE') {
      return
    }

    if (!response.data) {
      throw new Error('No response from API')
    }

    return camelCaseKeys(response.data, { deep: true })
  } catch (e) {
    throw new Error(
      `Path: ${path}, params: ${JSON?.stringify?.(params)}, data: ${JSON?.stringify?.(
        data
      )}, error: ${e}`
    )
  }
}

export const apiFetchPaginated = async (args: APIFetchArgs) => {
  let data = (await apiFetch(args)) as PaginatedAPIFetchResults
  let results = data.results
  while (data.next !== null) {
    args.path = data.next
    data = await apiFetch(args)
    results = results.concat(data.results)
  }
  return results
}

export const searchAddress = async (
  placeId?: string,
  address?: string,
  city?: string
): Promise<Suggestion> => {
  try {
    const data = await apiFetch({
      path: city
        ? `/building/search/?place_id=${placeId}&address=${address}&city=${city}`
        : `/building/search/?place_id=${placeId}&address=${address}`,
      baseURL: baseBuildingURL,
      noOrigin: true,
    })
    if (data) {
      return {
        buildingId: data.buildingSlug,
        address: titleCase(data.fullAddress),
        addressSlug: data.slug,
        productBuildingId: data.buildingId,
        cityId: data.cityId,
      } as Suggestion
    }
  } catch (err) {
    return null
  }
}
