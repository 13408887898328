import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'
import { AddressesApi, BuildingApi, ContactApi, ReviewApi } from 'src/common/types'

import {
  BuildingAggResponse,
  BuildingMetadata,
  ContactAggResponse,
  OpenListing,
  Suggestion,
} from '@openigloo/common'
import { titleCase } from '@utility/Utilities'

import { apiFetch } from './Api'

const {
  publicRuntimeConfig: { BACKEND_API_BUILDING, BACKEND_API_REVIEW },
} = getConfig()

const baseBuildingURL = BACKEND_API_BUILDING
const baseReviewURL = BACKEND_API_REVIEW

// New Building Service

export async function getBuildingApi(
  id: string,
  addressSlug: string,
  token?: string
): Promise<BuildingApi> {
  const data = await apiFetch({
    path: `/building/get/${id}/${addressSlug}/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data
}

export async function getBuildingOpenDataApi(id: string): Promise<any> {
  const data = await apiFetch({
    path: `/building/${id}/update_open_data/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getBuildingContactApi(
  id: string,
  page?: number
): Promise<{ results: ContactApi[]; count: number; next: string }> {
  return await apiFetch({
    path: `/building/${id}/contacts/?page=${page}&page_size=6`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getBuildingSeoMetaApi(id: string): Promise<BuildingMetadata> {
  const data = await apiFetch({
    path: `/building/${id}/seo_meta/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getNewBuildingQuestions(id: string): Promise<any> {
  const data = await apiFetch({
    path: `/question/building_question_answers/${id}/`,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
  return data
}

export async function submitQuestionApi(token: string, payload: any): Promise<any> {
  const data = await apiFetch({
    path: `/question/submit_question/`,
    method: 'POST',
    authToken: token,
    baseURL: baseReviewURL,
    data: snakeCase(payload),
    noOrigin: true,
  })
  return data
}

export async function submitAnswerApi(token: string, payload: any, id: string): Promise<any> {
  const data = await apiFetch({
    path: `/question/${id}/submit_answer/`,
    method: 'POST',
    authToken: token,
    baseURL: baseReviewURL,
    data: snakeCase(payload),
    noOrigin: true,
  })
  return data
}

export async function buildingUploadPhotosApi(id: string, payload: any, authToken: string) {
  return await apiFetch({
    method: 'POST',
    path: `/building/${id}/upload_image/`,
    data: payload,
    headers: { 'content-type': 'multipart/form-data' },
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getBuildingListingsApi(
  id: string,
  city: string,
  page?: number
): Promise<{ results: OpenListing[]; count: number; next: string }> {
  return await apiFetch({
    path: `/${city}/buildings/${id}/listing_units/?page=${page}&page_size=6`,
  })
}

export async function getComplaintsCountApi(buildingId: string, city: string): Promise<any> {
  return await apiFetch({
    path: `/${city}/buildings/complaints_count?building_id=${buildingId}`,
  })
}

export async function getBuildingAlternateAddressesApi(
  id: string,
  token: string
): Promise<{ results: AddressesApi[] }> {
  return await apiFetch({
    path: `/building/${id}/addresses/?page=1&page_size=100`,
    baseURL: baseBuildingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
}

export async function getBuildingsTopRated(
  pageSize: number,
  city: string
): Promise<{ results: BuildingApi[] }> {
  return await apiFetch({
    path: `/building/top_rated/new-york?page_size=${pageSize}&city=${city}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getBuildingsTopReviewed(
  pageSize: number,
  city: string
): Promise<{ results: BuildingApi[] }> {
  return await apiFetch({
    path: `/building/top_reviewed/new-york?page_size=${pageSize}&city=${city}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export function getResponsePercentages(
  responses: BuildingAggResponse | ContactAggResponse
): BuildingAggResponse {
  const { numReviews } = responses
  return {
    ...responses,
    recommendPerc:
      numReviews > 0 ? Math.ceil((responses.numRecommendBuilding / numReviews) * 100) : null,
    approvePerc: numReviews > 0 ? Math.ceil((responses.numApproveOwner / numReviews) * 100) : null,
    recDepositPerc:
      responses.numReceivedDepositReviews > 0
        ? Math.ceil((responses.numReceivedDeposit / responses.numReceivedDepositReviews) * 100)
        : null,
  }
}

export async function getNewBuildingResponses(
  buildingId: string,
  authToken?: string
): Promise<BuildingAggResponse> {
  const data = (await apiFetch({
    path: `/review/building_responses/${buildingId}/`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })) as BuildingAggResponse

  return getResponsePercentages(data) as BuildingAggResponse
}

export async function getNewBuildingRatings(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    path: `/review/building_ratings/${buildingId}/`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getNewNeighborhoodRatings(
  authToken: string,
  neighborhoodId: string
): Promise<any> {
  return await apiFetch({
    path: `/review/neighborhood_ratings/${neighborhoodId}/`,
    authToken: authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getBuildingReviewsPaginated(
  buildingId: string,
  authToken: string,
  page: number
): Promise<{ results: ReviewApi[]; next: string; count: number }> {
  return await apiFetch({
    path: `/review/building_reviews/${buildingId}/?page=${page}&page_size=5`,
    baseURL: baseReviewURL,
    noOrigin: true,
    authToken: authToken,
  })
}

export const searchAddress = async (
  address: string,
  placeId?: string,
  city?: string
): Promise<Suggestion> => {
  try {
    const data = await apiFetch({
      path: city
        ? `/building/search/?place_id=${placeId}&address=${address}&city=${city}`
        : `/building/search/?place_id=${placeId}&address=${address}`,
      baseURL: baseBuildingURL,
      noOrigin: true,
    })
    if (data) {
      return {
        buildingId: data.buildingSlug,
        address: titleCase(data.fullAddress),
        addressSlug: data.slug,
        productBuildingId: data.buildingId,
        cityId: data.cityId,
      } as Suggestion
    }
  } catch (err) {
    return null
  }
}

export async function getSearchAutoCompleteApi(address: string, token: string): Promise<any> {
  const data = await apiFetch({
    path: `/building/autocomplete/?address=${address}&token=${token}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getSearchNycAutoCompleteApi(address: string, token: string): Promise<any> {
  const data = await apiFetch({
    path: `/building/nyc_autocomplete/?address=${address}&token=${token}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getSearchItemApi(placeId: string, token: string): Promise<any> {
  const data = await apiFetch({
    path: `/building/get_address_from_place_id/?place_id=${placeId}&token=${token}`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data
}

export async function getBuildingResources(): Promise<any> {
  const data = await apiFetch({
    path: `/resource/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
  return data.results
}

export async function updateViewedBuildings(payload: any, authToken: string): Promise<any> {
  return await apiFetch({
    method: 'POST',
    path: `/explorer/track_viewed_building/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
    authToken: authToken,
    data: snakeCase(payload),
  })
}
