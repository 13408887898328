import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import Icon from '../../../icons'
import {
  OIButtonIconSize,
  OIButtonProps,
  OIButtonSize,
  OIButtonStatus,
  OIButtonType,
} from './interface'

const getButtonColors = (type) => {
  switch (type) {
    case OIButtonType.PRIMARY:
      return 'bg-primary text-white border-primary'
    case OIButtonType.SECONDARY:
      return 'bg-secondaryAlt text-primarytext border-primary'
    case OIButtonType.TERTIARY:
      return 'bg-white text-tertiaryText border-border'
    default:
      return 'bg-primary text-white border-primary'
  }
}

const getButtonStatusStyles = (buttonStatus) => {
  switch (buttonStatus) {
    case OIButtonStatus.SUCCESSFUL:
      return 'bg-success text-white border-success'
    case OIButtonStatus.WARNING:
      return 'bg-warning text-white border-warning'
    case OIButtonStatus.ERROR:
      return 'bg-error text-white border-error'
    default:
      return 'bg-success text-white border-success'
  }
}

const getButtonSizes = (size) => {
  switch (size) {
    case OIButtonSize.STANDARD:
      return 'px-4 py-2'
    case OIButtonSize.LARGE:
      return 'px-8 py-4'
    default:
      return ''
  }
}

const BASE_STYLES = 'inline-flex items-center rounded-full border text-xs justify-center'
const TEXT_ONLY_STYLES = 'border-none text-primary'
const DISABLED_STYLES = 'bg-light-30 border-light-40 text-mid-100'

const getLabelStyles = (icon, textOnly, disabled, size, status, type) => {
  return textOnly
    ? TEXT_ONLY_STYLES + `${icon ? '' : ' underline'}`
    : `${getButtonSizes(size)} ${
        disabled ? DISABLED_STYLES : status ? getButtonStatusStyles(status) : getButtonColors(type)
      }`
}

const getIconStyles = (type) => {
  switch (type) {
    case OIButtonType.PRIMARY:
      return 'text-blue-200 bg-secondaryAlt border-none'
    case OIButtonType.SECONDARY:
      return 'text-primary bg-white'
    case OIButtonType.TERTIARY:
      return 'text-blue-200 bg-light-20 border-none'
    default:
      return 'text-blue-200 bg-secondaryAlt border-none'
  }
}

export const OIButton = ({
  onClick,
  label = '',
  disabled = false,
  status = '',
  type = OIButtonType.PRIMARY,
  size = OIButtonSize.STANDARD,
  noBackground = false,
  icon = '',
  iconPositionLeft = true,
  iconColor = '',
  iconSize,
  style = '',
  isLoading = false,
  loaderColor = '#D8D8F4',
  loaderSize = 16,
  labelStyle = '',
  ...props
}: OIButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled || isLoading}
      className={` 
      ${BASE_STYLES} 
      ${
        label
          ? getLabelStyles(icon, noBackground, disabled, size, status, type)
          : getIconStyles(type)
      } 
      ${isLoading || disabled ? 'opacity-50' : ''}
      ${style} `}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <div className="flex w-full justify-center">
          <ClipLoader size={loaderSize} color={loaderColor} />
        </div>
      ) : (
        <>
          {icon && (
            <span
              className={`${noBackground || !iconPositionLeft ? 'order-2' : 'order-1'} ${
                label ? (iconPositionLeft ? 'pr-2' : '') : 'p-2'
              } ${iconColor ? `text-${iconColor}` : ''}
          `}
            >
              {<Icon name={icon} size={iconSize} />}
            </span>
          )}
          {label && (
            <span
              className={`${
                noBackground || !iconPositionLeft ? 'order-1 pr-2' : 'order-2'
              } ${labelStyle}`}
            >
              {label}
            </span>
          )}
        </>
      )}
    </button>
  )
}

export const OIPrimaryButton = ({ ...rest }: OIButtonProps) => {
  return <OIButton type={OIButtonType.PRIMARY} {...rest} />
}
export const OISecondaryButton = ({ ...rest }: OIButtonProps) => {
  return <OIButton type={OIButtonType.SECONDARY} {...rest} />
}
export const OITertiaryButton = ({ ...rest }: OIButtonProps) => {
  return <OIButton type={OIButtonType.TERTIARY} {...rest} />
}

export const OIStatusButton = ({
  type,
  status,
  disabled,
  ...rest
}: {
  type: OIButtonType
  disabled: boolean
  status: OIButtonStatus
}) => {
  return <OIButton type={type} status={status} disabled={disabled} {...rest} />
}

export const OITextButton = ({ label, ...rest }) => {
  return <OIButton label={label} noBackground {...rest} />
}

export const OITextIconButton = ({ label, icon, iconSize = OIButtonIconSize.MEDIUM, ...rest }) => {
  return <OIButton label={label} icon={icon} iconSize={iconSize} {...rest} />
}
export const OIIconButton = ({
  icon,
  iconSize = OIButtonIconSize.MEDIUM,
  type = OIButtonType.PRIMARY,
  ...rest
}) => {
  return <OIButton icon={icon} iconSize={iconSize} type={type} {...rest} />
}

export default OIButton
export * from './interface'
