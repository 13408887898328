import { HYDRATE } from 'next-redux-wrapper'
import { BuildingApi, ReviewApi } from 'src/common/types'

import { BuildingState } from '@localtypes/Store'
import {
  Building,
  BuildingAggResponse,
  BuildingFaqs,
  BuildingOverview,
  BuildingRentStabilizedData,
  BuildingViolations,
  BuildingViolationsEvictions,
  BuildingViolationsLitigations,
  Contact,
  OpenListing,
  ProductBuilding,
  Question,
  Review,
  Suggestion,
  Tenancy,
} from '@openigloo/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: BuildingState = {
  building: null,
  newbuilding: null,
  contacts: [],
  listings: [],
  reviews: [],
  limitedReviews: [],
  questions: [],
  aggResponses: null,
  overview: null,
  isFetching: false,
  currentUserTenancy: null,
  faqs: [],
  buildingViolations: null,
  buildingEvictions: null,
  buildingLitigations: null,
  buildingBedbugs: null,
  buildingRentStabilizedUnits: null,
  buildingAlternateAddresses: null,
  complaintsCount: null,
  reviewCount: null,
}

export const buildingSlice = createSlice({
  name: 'currentBuilding',
  initialState,
  reducers: {
    reset: () => initialState,
    setBuilding(state, action: PayloadAction<Building>) {
      state.building = action.payload
    },
    setNewBuilding(state, action: PayloadAction<BuildingApi>) {
      state.newbuilding = action.payload
    },
    setProductBuilding(state, action: PayloadAction<ProductBuilding>) {
      state.building.productBuilding = action.payload
    },
    setContacts(state, action: PayloadAction<Contact[]>) {
      state.contacts = action.payload
    },
    setListings(state, action: PayloadAction<OpenListing[]>) {
      state.listings = action.payload
    },
    setQuestions(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload
    },
    setAggResponses(state, action: PayloadAction<BuildingAggResponse>) {
      state.aggResponses = action.payload
    },
    setOverview(state, action: PayloadAction<BuildingOverview>) {
      state.overview = action.payload
    },
    setReviews(state, action: PayloadAction<ReviewApi[]>) {
      state.reviews = action.payload
    },
    setReviewsCount(state, action: PayloadAction<number>) {
      state.reviewCount = action.payload
    },
    setReviewsPaginated(state, action: PayloadAction<ReviewApi[]>) {
      state.reviews = [...state.reviews, ...action.payload]
    },
    setFAQs(state, action: PayloadAction<BuildingFaqs>) {
      state.faqs = action.payload
    },
    setCurrentUserTenancy(state, action: PayloadAction<Tenancy>) {
      state.currentUserTenancy = action.payload
    },
    setNumHelpfulForReview(state, action: PayloadAction<{ review: Review; value: boolean }>) {
      const { value, review } = action.payload
      const matchReview = state.reviews.find((r) => r.id === review.id)
      if (value) {
        matchReview.noHelpfulFor += 1
      } else {
        matchReview.noHelpfulFor -= 1
      }
    },
    setLimitedReviews(state, action: PayloadAction<Review[]>) {
      state.limitedReviews = action.payload
    },
    setBuildingViolations(state, action: PayloadAction<BuildingViolations>) {
      state.buildingViolations = action.payload
    },
    setBuildingEvictions(state, action: PayloadAction<BuildingViolationsEvictions>) {
      state.buildingEvictions = action.payload
    },
    setBuildingLitigations(state, action: PayloadAction<BuildingViolationsLitigations>) {
      state.buildingLitigations = action.payload
    },
    setBuildingBedbugs(state, action: PayloadAction<BuildingViolations>) {
      state.buildingBedbugs = action.payload
    },
    setBuildingRentStabilizedUnits(state, action: PayloadAction<BuildingRentStabilizedData>) {
      state.buildingRentStabilizedUnits = action.payload
    },
    setBuildingAlternateAddresses(state, action: PayloadAction<Suggestion[]>) {
      state.buildingAlternateAddresses = action.payload
    },
    setBuildingComplaintsCount(state, action: PayloadAction<any>) {
      state.complaintsCount = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.currentBuilding,
      }
    },
  },
})

export default buildingSlice.reducer
