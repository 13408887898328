import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'

import { apiFetch } from './Api'

// New Rental Reports APIS

const {
  publicRuntimeConfig: { BACKEND_API_BUILDING, BACKEND_API_REVIEW },
} = getConfig()

const baseBuildingURL = BACKEND_API_BUILDING
const baseReviewURL = BACKEND_API_REVIEW

export async function getExplorerData(authToken: string): Promise<any> {
  return await apiFetch({
    path: `/explorer/`,
    authToken: authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getSubscriptionPrices(): Promise<any> {
  return await apiFetch({
    path: `/explorer/subscription_prices/`,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getNewSubscriptionPayment(data: any, authToken: string): Promise<any> {
  return await apiFetch({
    method: 'post',
    path: `/explorer/get_access_subscription_page/`,
    authToken,
    data: snakeCase(data),
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function addNewReport(data: any, authToken: string): Promise<any> {
  return await apiFetch({
    method: 'post',
    path: `/explorer/add_report/`,
    authToken,
    data: snakeCase(data),
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getProfileSubscriptionLink(authToken: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/explorer/subscription_management_page/`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getViolationHistoryData(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/violation_history/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getViolationHistoryDataYear(
  authToken: string,
  buildingId: string,
  year: string
): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/violation_history/?building_id=${buildingId}&year=${year}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getComplaintsHistoryData(
  authToken: string,
  buildingId: string
): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/complaints/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getRodentsData(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/rodent_inspection/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getBedbugsData(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/bedbugs/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getTenancyData(
  authToken: string,
  buildingId: string,
  nbr_id: string
): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/review/tenancy_length/${buildingId}/?neighborhood_id=${nbr_id}`,
    authToken,
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getRentData(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/rent_value/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}

export async function getConstructionData(authToken: string, buildingId: string): Promise<any> {
  return await apiFetch({
    method: 'get',
    path: `/report/construction/?building_id=${buildingId}`,
    authToken,
    baseURL: baseBuildingURL,
    noOrigin: true,
  })
}
