import getConfig from 'next/config'
import { getFirebaseAuth } from 'src/lib/firebase'

const { publicRuntimeConfig } = getConfig()

export type ActionCardItem = {
  action: string
  data: any
}

export function getAnonymousActionCardItems(): ActionCardItem[] {
  return [
    {
      action: 'refer_openigloo',
      data: null,
    },
    {
      action: 'explore_listings',
      data: null,
    },
    {
      action: 'create_review',
      data: null,
    },
  ]
}

export const getDeepLink = async () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const token = await getFirebaseAuth().currentUser?.getIdToken()
    if (!token) {
      resolve('https://link.openigloo.com/web-share')
      return
    }
    const link = `${window.location.host}`
    const branch = (await import('branch-sdk'))?.default
    if (branch) {
      branch.init(publicRuntimeConfig.BRANCH_IO_KEY, {}, () => {
        branch.link(
          {
            data: {
              $og_title: 'Check out openigloo!',
              $og_description:
                'You can read and share building reviews, and browse available apartments.',
              $og_image_url: 'https://www.openigloo.com/landing-cover.png',
              $desktop_url: `${window.location.host}`,
              $after_click_url: `${window.location.host}`,
            },
          },
          (_err, data) => {
            if (data) {
              resolve(data)
            } else {
              resolve(link)
            }
          }
        )
      })
    } else {
      resolve(link)
    }
  })
}
