import { MouseEventHandler } from 'react'

import { AiOutlineLoading3Quarters } from '@react-icons/all-files/ai/AiOutlineLoading3Quarters'

export default function ActionButton({
  label,
  onClick,
  customStyle = '',
  style = 'primary',
  size = 'normal',
  disabled = false,
  isLoading = false,
  spinSize = 'text-base',
}: {
  label: string | JSX.Element
  onClick: MouseEventHandler
  customStyle?: string
  style?: string
  size?: string
  disabled?: boolean
  isLoading?: boolean
  spinSize?: string
}): JSX.Element {
  let bgColor = 'bg-primary'
  if (style === 'secondary') {
    bgColor = 'bg-secondary'
  } else if (style === 'warning') {
    bgColor = 'bg-red-100'
  } else if (style === 'neutral') {
    bgColor = 'bg-bluegray-300'
  } else if (style === 'none') {
    bgColor = ''
  }

  let minWidth = 'min-w-56'
  if (size === 'large') {
    minWidth = 'min-w-72'
  }
  if (size === 'small') {
    minWidth = 'min-w-40'
  }
  if (size === 'xsmall') {
    minWidth = 'min-w-24'
  }
  if (size === 'none') {
    minWidth = ''
  }

  return (
    <div>
      <button
        disabled={disabled}
        onClick={onClick}
        className={` text-white ${bgColor} rounded-3xl py-2 px-4 focus:outline-none ${minWidth}
        m-1 mx-auto inline-flex cursor-pointer items-center justify-center text-center ${
          disabled ? 'opacity-40' : ''
        } ${customStyle}`}
      >
        {isLoading ? <AiOutlineLoading3Quarters className={`animate-spin ${spinSize}`} /> : label}
      </button>
    </div>
  )
}
