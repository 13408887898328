import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'

import { ApplicantType, ApplicationType } from '@openigloo/common'

import { apiFetch } from './Api'

const {
  publicRuntimeConfig: { BACKEND_API_APPLICATION },
} = getConfig()

const baseApplicationURL = BACKEND_API_APPLICATION

// New Application APIs

export async function getApplicant(authToken: string): Promise<ApplicantType> {
  return await apiFetch({
    path: `/applicant/`,
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function createApplicant(
  authToken: string,
  data: ApplicantType
): Promise<ApplicantType> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/applicant/create_applicant/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function updateApplicantApi(
  authToken: string,
  data: ApplicantType
): Promise<ApplicantType> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/applicant/update_applicant/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function getAllApplications(authToken: string): Promise<ApplicationType[]> {
  return (await apiFetch({
    path: `/application/`,
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })) as ApplicationType[]
}

export async function createApplication(authToken: string, data: any): Promise<ApplicationType> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/application/create_application/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function updateApplicationApi(
  authToken: string,
  data: ApplicationType,
  id: string
): Promise<ApplicationType> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/application/${id}/update_application/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function editApplicationApi(authToken: string, id: string): Promise<ApplicationType> {
  return await apiFetch({
    data: {},
    path: `/application/${id}/edit_application/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function uploadApplicantDocument(
  authToken: string,
  data: any
): Promise<ApplicantType> {
  return await apiFetch({
    data,
    path: `/applicant/upload_document/`,
    method: 'post',
    authToken,
    headers: { 'content-type': 'multipart/form-data' },
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function deleteApplicantDocument(
  authToken: string,
  data: any
): Promise<ApplicantType> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/applicant/delete_document/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function addApplicationRecipients(
  authToken: string,
  applicationId: string,
  data: any
): Promise<any> {
  return await apiFetch({
    data: snakeCase(data),
    path: `/application/${applicationId}/add_recipients/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function getApplicantFee(
  authToken: string,
  successUrl: string,
  failUrl: string
): Promise<any> {
  return await apiFetch({
    data: {
      success_url: successUrl,
      fail_url: failUrl,
    },
    method: 'post',
    path: `/applicant/get_applicant_fee_payment_page/`,
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function submitApplicationApi(authToken: string, applicationId: string): Promise<any> {
  return await apiFetch({
    path: `/application/${applicationId}/submit_application/`,
    method: 'post',
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function getPlaidLinkToken(authToken: string) {
  return await apiFetch({
    method: 'get',
    path: `/applicant/get_link_token/?client=web&redirect_uri=${window.location.origin}/profile/application-profile`,
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
  })
}

export async function requestForPlaidReport(authToken, plaidPublicToken: string) {
  return await apiFetch({
    method: 'post',
    path: `/applicant/update_plaid_connection_token/`,
    authToken,
    baseURL: baseApplicationURL,
    noOrigin: true,
    data: {
      token: plaidPublicToken,
    },
  })
}

export async function getRecipientApplication(token: string) {
  return await apiFetch({
    method: 'post',
    path: `/application/get_from_recipient_token/`,
    baseURL: baseApplicationURL,
    noOrigin: true,
    data: {
      token: token,
    },
  })
}

export async function disconnectPlaidApi(payload: any, token: string) {
  return await apiFetch({
    method: 'post',
    path: `/applicant/disconnect_plaid_account/`,
    baseURL: baseApplicationURL,
    noOrigin: true,
    data: snakeCase(payload),
    authToken: token,
  })
}
