import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { unlinkPhone, verifyPhone } from 'src/data/newUserProfile'
import { segment } from 'src/lib/Segments'
import { getFirebaseAuth } from 'src/lib/firebase'

import { OIInput } from '@components/UI/atoms'
import ActionButton from '@components/buttons/actionButton'
import { fetchCurrentUserProfile } from '@redux/actions/user'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { formatPhoneForDisplay, formatToE164 } from '@utility/Utilities'

const auth = getFirebaseAuth()

function PhoneVerificationModal({ isVisible = false, onClose }) {
  const dispatch = useThunkDispatch()
  const { profile } = useAppSelector((state) => state.currentUser)
  const [code, setCode] = useState('')
  const [resendDisabled, setResendDisabled] = useState(true)
  const [timer, setTimer] = useState(30)
  const [vid, setVid] = useState('')
  const [showCodeError, setShowCodeError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(null)
  const [triggerOnce, setTriggerOnce] = useState(true)

  const { isLoggedIn, token } = useAppSelector((state) => state.currentUser)

  useEffect(() => {
    if (isLoggedIn && token && triggerOnce) {
      setTriggerOnce(false)
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear() // ✅ Clears reCAPTCHA
        window.recaptchaVerifier = null // ✅ Prevents Firebase from using an invalid reference
      }
      submitPhone()
    }
  }, [isLoggedIn && token])

  function submitPhone() {
    // ✅ Reinitialize reCAPTCHA each time
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        auth
      )
    }
    const recaptchaVerifier = window.recaptchaVerifier // ✅ Reuse existing instance

    // ✅ Send OTP
    const provider = new PhoneAuthProvider(auth)

    provider
      .verifyPhoneNumber(formatToE164(profile?.phone), recaptchaVerifier)
      .then((verificationId) => {
        setVid(verificationId)
      })
      .catch((error) => {
        // console.error('🔥 Full Firebase Error:', error)
        // console.log('❌ Error Code:', error.code)
        // console.log('❌ Error Message:', error.message)
        // console.log('❌ Error Details:', JSON.stringify(error, null, 2))

        switch (error.code) {
          case 'auth/invalid-phone-number':
            setShowError(
              'Invalid phone number format. Please enter a valid number. Please contact our support for assistance'
            )
            break
          case 'auth/quota-exceeded':
            setShowError('Too many requests. Please try again later.')
            break
          case 'auth/too-many-requests':
            setShowError("You've requested too many OTPs. Please wait a while before trying again.")
            break
          default:
            setShowError(
              'An unexpected error occurred. Please try again later or contact our support'
            )
        }
      })
  }

  useEffect(() => {
    let interval
    if (resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval)
            setResendDisabled(false) // ✅ Enable Resend Button
            return 30 // Reset Timer
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(interval) // Cleanup timer on unmount
  }, [resendDisabled])

  async function submitCode() {
    segment?.unit_contact_agent_sms_consent_submit_code()
    try {
      setLoading(true)
      if (!auth.currentUser) {
        throw new Error('User is not logged in.')
      }

      // ✅ Step 1: Create a credential from the verification ID and OTP
      const credential = PhoneAuthProvider.credential(vid, code)
      // ✅ Step 2: Attempt to update phone number (this will fail if it's linked to another account)
      try {
        await updatePhoneNumber(auth.currentUser, credential)
        // console.log('✅ Phone number updated successfully!')
        await verifyPhone(token)
        await dispatch(fetchCurrentUserProfile(token))
        onClose()
        return
      } catch (error) {
        if (error.code == 'auth/account-exists-with-different-credential') {
          // console.log('Account exist please unlink')
          await unlinkPhone(token)
        } else {
          setLoading(false)
          switch (error.code) {
            case 'auth/code-expired':
              setShowCodeError('Code expired please resend a new code')
              break
            case 'auth/requires-recent-login':
              setShowCodeError('You need to re-authenticate before updating your phone number.')
              break
            case 'auth/invalid-verification-code':
              setShowCodeError('Incorrect code, please try again or resend a new code')
              break
            case 'auth/too-many-requests':
              setShowCodeError('Too many attempts. Please wait and try again later.')
              break
            case 'auth/account-exists-with-different-credential':
              setShowCodeError(
                'This number is linked to another email, please contact support for assistance'
              )
              break
            default:
              setShowCodeError('An unexpected error occurred. Please try again later')
          }
          return
        }
      }

      // ✅ Step 4: Retry updating phone number after unlinking
      await updatePhoneNumber(auth.currentUser, credential)
      // console.log('✅ Phone number successfully updated after unlinking!')
      await verifyPhone(token)
      await dispatch(fetchCurrentUserProfile(token))
      onClose()
    } catch (error) {
      // console.error('❌ Phone Update Failed:', error)
      setLoading(false)
      switch (error.code) {
        case 'auth/code-expired':
          setShowCodeError('Code expired please resend a new code')
          break
        case 'auth/requires-recent-login':
          setShowCodeError('You need to re-authenticate before updating your phone number.')
          break
        case 'auth/invalid-verification-code':
          setShowCodeError('Incorrect code, please try again or resend a new code')
          break
        case 'auth/too-many-requests':
          setShowCodeError('Too many attempts. Please wait and try again later.')
          break
        case 'auth/account-exists-with-different-credential':
          setShowCodeError(
            'This number is linked to another email, please contact support for assistance'
          )
          break
        default:
          setShowCodeError('An unexpected error occurred. Please try again later')
      }
    }
  }

  function resendCode() {
    segment?.unit_contact_agent_sms_consent_resend_code()
    setResendDisabled(true) // 🔥 Disable Resend Button
    setTimer(59) // Reset Timer
    submitPhone()
  }

  if (!isVisible) {
    return null
  } else {
    return (
      <div className="fixed top-0 bottom-0 left-0 z-[400] !m-0 h-screen w-full bg-black bg-opacity-80 text-black">
        <div
          className="slide-up mx-auto my-20 h-auto w-fit max-w-md overflow-y-auto rounded-2xl bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <div id="recaptcha-container"></div>
          <div className="flex items-center justify-between rounded-t-2xl bg-light-10 py-5 px-8">
            <div className="pr-6 text-xl font-semibold text-dark-900">Verify Your Phone</div>
            <button onClick={onClose} className="focus:outline-none">
              <GrClose size={16} />
            </button>
          </div>
          {showError ? (
            <div className="py-6 px-8">
              <div className="pb-2 text-center font-semibold text-dark-900">{showError}</div>
              <div className="text-center">
                <ActionButton
                  label="Close"
                  onClick={onClose}
                  disabled={loading}
                  size="xsmall"
                  customStyle="border border-light-40 mt-2 !bg-white !text-dark-600 !text-sm"
                />
              </div>
            </div>
          ) : (
            <div className="py-6 px-8">
              <div className="text-dark-700">
                Enter the verification code we sent to your phone number at{' '}
                <span className="font-semibold text-dark-900">
                  {formatPhoneForDisplay(profile?.phone)}
                </span>
              </div>
              <div className="mt-6 font-semibold text-dark-900">Verification code</div>
              <OIInput
                type={'tel'}
                placeholder="Enter your code"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value)
                  setShowCodeError(null)
                }}
                onFocus={() => {
                  segment?.unit_contact_agent_sms_consent_verify_code()
                }}
                containerStyle={'mt-2'}
              />
              {showCodeError && <div className="text-sm text-red-400">{showCodeError}</div>}
              <div className="text-center">
                <ActionButton
                  onClick={resendCode}
                  label={resendDisabled ? `Resend code in ${timer}s` : 'Resend code'}
                  size="xsmall"
                  customStyle="!text-sm !text-bluegray-300 !bg-white border-none underline"
                  disabled={resendDisabled || loading}
                />
                <ActionButton
                  onClick={submitCode}
                  label={'Submit code'}
                  size="xsmall"
                  customStyle="!text-sm mt-2"
                  disabled={code?.length !== 6}
                  isLoading={loading}
                />
                <ActionButton
                  label="I will do it later"
                  onClick={() => {
                    segment?.unit_contact_agent_sms_consent_do_later()
                    onClose()
                  }}
                  disabled={loading}
                  size="xsmall"
                  customStyle="border border-light-40 mt-2 !bg-white !text-dark-600 !text-sm"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PhoneVerificationModal
