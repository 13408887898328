import React from 'react'

import { OIFontColors, OIFontSizes, OIFontWeights, OITextType } from './interface'
import { OITextProps } from './interface'

const getSizeByType = (type: OITextType) => {
  switch (type) {
    case OITextType.BODY:
      return OIFontSizes.SM
    case OITextType.SUBTITLE:
    case OITextType.HEADLINE6:
      return OIFontSizes.ONE_FIVE_XL
    case OITextType.HEADLINE5:
      return OIFontSizes.TWO_XL
    case OITextType.HEADLINE4:
      return OIFontSizes.FOUR_XL
    case OITextType.HEADLINE3:
      return OIFontSizes.FIVE_XL
    case OITextType.HEADLINE2:
      return OIFontSizes.SIX_XL
    case OITextType.HEADLINE1:
      return OIFontSizes.EIGHT_XL
    default:
      return OIFontSizes.BASE
  }
}

export const OIText = ({
  type = '',
  color = OIFontColors.PRIMARY,
  weight = OIFontWeights.NORMAL,
  size = '',
  children,
  customTextStyles = '',
  ...rest
}: OITextProps) => {
  const sizeRef = type ? getSizeByType(type) : size || OIFontSizes.BASE

  const TEXT_COLOR = `text-${color || OIFontColors.PRIMARY}`
  const TEXT_SIZE = `text-${sizeRef}`
  const TEXT_WEIGHT = `font-${weight || OIFontWeights.NORMAL}`
  return (
    <div
      className={`font-body ${TEXT_COLOR} ${TEXT_SIZE} ${TEXT_WEIGHT} ${customTextStyles} `}
      {...rest}
    >
      {children}
    </div>
  )
}

export const OITextBodyBold = ({ ...rest }: OITextProps) => {
  return <OIText weight={OIFontWeights.SEMI_BOLD} size={OIFontSizes.BASE} {...rest} />
}

export const OICaption = ({ ...rest }: OITextProps) => {
  return <OIText size={OIFontSizes.XS} {...rest} />
}

export const OICaptionBold = ({ ...rest }: OITextProps) => {
  return <OIText size={OIFontSizes.XS} weight={OIFontWeights.SEMI_BOLD} {...rest} />
}

export const OIOverline = ({ children, ...rest }: OITextProps) => {
  return (
    <OIText size={OIFontSizes.XS} {...rest}>
      {children.toUpper()}
    </OIText>
  )
}

export const OITextSubtitle = ({ children, ...rest }) => (
  <OIText type={OITextType.SUBTITLE} {...rest}>
    {children}
  </OIText>
)

export const OITextSubtitleBold = ({ children, ...rest }) => (
  <OIText type={OITextType.SUBTITLE} weight={OIFontWeights.SEMI_BOLD} {...rest}>
    {children}
  </OIText>
)

export const OITextHeading6 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE6} {...rest}>
    {children}
  </OIText>
)
export const OITextHeading5 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE5} {...rest}>
    {children}
  </OIText>
)
export const OITextHeading4 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE4} {...rest}>
    {children}
  </OIText>
)
export const OITextHeading3 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE3} {...rest}>
    {children}
  </OIText>
)
export const OITextHeading2 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE2} {...rest}>
    {children}
  </OIText>
)
export const OITextHeading1 = ({ children, ...rest }) => (
  <OIText type={OITextType.HEADLINE1} {...rest}>
    {children}
  </OIText>
)

export default OIText
export * from './interface'
