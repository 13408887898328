import { segment } from 'src/lib/Segments'

export default function RadioButton({
  options,
  selectedValue,
  onSelect,
  style = '',
  eventName = null,
  segmentBase = 'other',
  customStyle = '',
  disabled = false,
}) {
  return (
    <div className={`flex flex-row ${style}`}>
      {Object.keys(options).map((key) => {
        const value = options[key]
        const bg =
          selectedValue === value
            ? 'bg-bluegray-200'
            : disabled
            ? 'bg-light-40'
            : 'bg-light-gray border border-light-50'
        return (
          <div
            className={`mr-4 flex cursor-pointer flex-row items-center ${customStyle} ${
              disabled && 'cursor-not-allowed'
            }`}
            key={key}
            onClick={() => {
              if (disabled) {
                return
              }
              onSelect(value)
              eventName && segment?.[segmentBase + '_radio_check_btn']?.(eventName, value)
            }}
          >
            <div
              className={`relative flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-xl  ${bg}`}
            >
              <div
                className={`relative h-2 w-2 rounded-full ${
                  selectedValue === value ? 'bg-blue-25' : ''
                }`}
              ></div>
            </div>
            <span
              className={`ml-2 text-sm ${
                selectedValue === value
                  ? 'text-dark-800'
                  : disabled
                  ? 'text-light-40'
                  : 'text-dark-800'
              }`}
            >
              {key}
            </span>
          </div>
        )
      })}
    </div>
  )
}
