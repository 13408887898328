import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect, useState } from 'react'

// import GoogleOneTap from '@components/auth/GoogleOnetap'
import { setSubscriptionPrices, updateListingInterestBody } from '@redux/actions'
import { addVisitedPagesCount, resetReferralLink, setReferralLink } from '@redux/actions/activity'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { wrapper } from '@redux/store'

import '../styles/chatlio.css'
import '../styles/slick.css' 
import '../styles/theme.css'
//IMPORTANT: this needs to be the last style file
import '../styles/global.sass'


import { segment, setUserAgentType } from '../lib/Segments'
import { getCurrentUser } from '../lib/firebase'
import {
  logoutCurrentUser,
  setAuthRequired,
  setCurrentCity,
  setCurrentUserProfile,
  setCurrentUserUnitApplications,
  setIsLoggedIn,
  setReviewerData,
  setUserReviews,
} from '../redux/actions/user'
import ErrorBoundary from '@components/errors/ErrorBoundary'
import { inIframe } from '@utility/Utilities'

import { setCurrentUserContactedUnits, setCurrentUserExplorerData, setCurrentUserSavedUnits } from '@redux/thunks/user'
import { newSearchSlice } from '@redux/reducers/newSearch'
import PhoneVerificationModal from '@components/newListing/phoneVerification'
import { userSlice } from '@redux/reducers/user'
import CompleteProfile from '@components/profile/CompleteProfileModal'

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const router = useRouter()
  const dispatch = useThunkDispatch()
  const isBrowser = typeof window !== 'undefined'
  const [showCompleteProfile, setShowCompleteProfile] = useState(false)
  const { currentCity, token, isLoggedIn, triggerConsent, consentEnabled, profile } = useAppSelector((state) => state.currentUser)

  //https://github.com/vercel/next.js/issues/4662
  const [initialRouteTracked, setInitialRouteTracked] = useState(false)

  if (isBrowser && !initialRouteTracked && !inIframe()) {
    setUserAgentType()
    segment.page('') //First Load Page Tracking
    setInitialRouteTracked(true)
    dispatch(addVisitedPagesCount())
  }

  useEffect(() => {
    if(!inIframe()){
      try{
        if (localStorage.getItem('city')) {
          const cityObj = JSON.parse(localStorage.getItem('city'))
          dispatch(setCurrentCity(cityObj))
        }
    } catch(e){
      console.log('error', e)
    }
  }}, [])

  useEffect(() => {
    if(!inIframe()){
      if (token) {
        dispatch(setReferralLink())
      } else {
        dispatch(resetReferralLink())
        dispatch(logoutCurrentUser())
      }
    }
  }, [token])

  useEffect(() => {
    if(!inIframe()){
      const handleRouteChange = (url: string): void => {
        setUserAgentType()
        segment.page(url) //SPA page change
        NProgress.done()
        // Hack to force hide chatlio
        const chatContainer = document.getElementById('chatlio-widget-container')
        if (chatContainer) {
          chatContainer.hidden = url !== '/'
        }
      }

      router.events.on('routeChangeStart', () => {
        NProgress.start()
      })
      router.events.on('routeChangeComplete', handleRouteChange)
      
      return () => {
        router.events.off('routeChangeStart', () => NProgress.start())
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])
  
  async function checkCurrentUser() { 
    if(!inIframe()){ 
      dispatch(setAuthRequired(false))
      dispatch(setCurrentUserSavedUnits({token}))
      dispatch(setCurrentUserContactedUnits({token}))
      dispatch(setCurrentUserUnitApplications(token))  
      dispatch(setReviewerData(token))
      dispatch(setUserReviews(token))
      dispatch(setCurrentUserExplorerData({ token }))
      dispatch(updateListingInterestBody(token))
    }
  }

  async function checkCurrentUserLogin() { 
    if(!inIframe()){
      const token = await getCurrentUser()  
      if (token) {
        return     
      } else {
        dispatch(setIsLoggedIn(false))
      }
    }
  }

  useEffect(() => {
      if(!inIframe()){
        checkCurrentUserLogin()
        dispatch(setSubscriptionPrices())
      }
  }, [])

  useEffect(() => {
    if(!inIframe() && token && isLoggedIn){
      checkCurrentUser()
    }
  }, [token, isLoggedIn])

  const getProfile = async()=>{
    if(!inIframe()){
      const token = await getCurrentUser()  
      if (token) {
        dispatch(setCurrentUserProfile(token))
      }
    }
  }

  useEffect(()=>{
    getProfile()
  },[router])

  useEffect(() => {
    if(!inIframe()){
      setUserAgentType()
    }
  },[currentCity])

  useEffect(() => {
    if(!inIframe()){
    const {utm_campaign, email_source} = router.query
      if(utm_campaign?.length){
        segment.track(utm_campaign?.toString(), '')
      }
      if (email_source?.length) {
        segment.track('loaded_from_email', {email_source})
      }
    }
  }, [router.query])

  // Listing Filters Sets

  useEffect(() => {
    if (localStorage?.getItem('nfilter')) {
      const data = JSON.parse(localStorage.getItem('nfilter'))
      dispatch(newSearchSlice.actions.setFilter(data))
    }
    if (localStorage?.getItem('sfilter')) {
      const data = JSON.parse(localStorage.getItem('sfilter'))
      dispatch(newSearchSlice.actions.setSearchFilter(data))
    }
    if (localStorage?.getItem('pfilter')) {
      const data = JSON.parse(localStorage.getItem('pfilter'))
      dispatch(newSearchSlice.actions.setProfileFilter(data))
    }
  }, [])

  const COMPLETE_PROFILE_CHECK_KEY = "lastProfileCheck"; // Key to store last check time
  const CHECK_INTERVAL_DAYS = 3; // Change this to 3 if you want a 3-day interval

  const checkProfileCompletion = (profile, setShowCompleteProfile) => {
    if (
      profile?.phone == null || profile?.phone === "" || 
      profile?.firstName == null || profile?.firstName === "" || 
      profile?.lastName == null || profile?.lastName === ""
    ) {
      if (!router?.asPath?.includes('subscription')) {
        setShowCompleteProfile(true);
      }
    }
  };

  useEffect(() => {
    if (token && isLoggedIn && profile?.email) {
      const lastCheck = localStorage.getItem(COMPLETE_PROFILE_CHECK_KEY);
      const now = Date.now();
      const twoDaysInMs = CHECK_INTERVAL_DAYS * 24 * 60 * 60 * 1000; // Convert days to milliseconds

      if ((!lastCheck || now - Number(lastCheck) >= twoDaysInMs) && !router?.asPath?.includes('subscription')) {
        checkProfileCompletion(profile, setShowCompleteProfile);
        localStorage.setItem(COMPLETE_PROFILE_CHECK_KEY, now.toString()); // Store current time
      }
    }
  }, [token, isLoggedIn, profile]);

  return (
    <>
      <Head>
        <title>openigloo</title>
        <meta name="viewport" content="initial-scale=1, width=device-width, maximum-scale=1, user-scalable=no" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <div className="flex min-h-screen flex-col bg-white">
        {/* {publicRuntimeConfig.isProd && !inIframe() ? <RegisterSW /> : null} */}
        {(triggerConsent && consentEnabled) && (
          <PhoneVerificationModal
            isVisible={triggerConsent && consentEnabled}
            onClose={() => {
              dispatch(userSlice.actions.setConsentEnabled(false))
              dispatch(userSlice.actions.setTriggerConsent(false))
            }}
          />
        )}
        {(showCompleteProfile &&
          <CompleteProfile onClose={() => setShowCompleteProfile(false)}/>
        )}
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </div>
      {/* {!inIframe() && <GoogleOneTap />} */}
    </>
  )
}

export default wrapper.withRedux(App)
