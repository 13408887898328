import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'
import { ReviewApi, ReviewerApi } from 'src/common/types'

import { apiFetch } from './Api'

const {
  publicRuntimeConfig: { BACKEND_API_REVIEW },
} = getConfig()

const baseReviewURL = BACKEND_API_REVIEW

// New Review Service

export async function getUserReviews(token: string): Promise<ReviewApi[]> {
  const data = await apiFetch({
    path: `/review/`,
    baseURL: baseReviewURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data.results
}

export async function getReviewById(token: string, reviewId: string): Promise<any> {
  const data = await apiFetch({
    path: `/review/${reviewId}/`,
    baseURL: baseReviewURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data
}

export async function addReview(token: string, dataPayload: ReviewApi): Promise<any> {
  const data = await apiFetch({
    path: `/review/add/`,
    baseURL: baseReviewURL,
    method: 'POST',
    noOrigin: true,
    ...(token && { authToken: token }),
    data: snakeCase(dataPayload),
  })
  return data
}

export async function updateReview(
  token: string,
  dataPayload: ReviewApi,
  reviewId: string
): Promise<any> {
  const finalPayload = { ...dataPayload, submissionPlatform: 'web' }
  const data = await apiFetch({
    path: `/review/${reviewId}/modify/`,
    baseURL: baseReviewURL,
    method: 'POST',
    noOrigin: true,
    ...(token && { authToken: token }),
    data: snakeCase(finalPayload),
  })
  return data
}

export async function updateReviewTenancy(
  token: string,
  payload: ReviewApi,
  buildingSlug: string
): Promise<any> {
  const data = await apiFetch({
    path: `/review/update_tenancy/${buildingSlug}/`,
    baseURL: baseReviewURL,
    method: 'POST',
    ...(token && { authToken: token }),
    data: snakeCase(payload),
    noOrigin: true,
  })
  return data
}

export async function reviewUploadPhotos(token: string, dataPayload: any, reviewId: string) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/add_image/`,
    data: dataPayload,
    headers: { 'content-type': 'multipart/form-data' },
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function reviewRemovePhotos(token: string, reviewId: string, payload: any) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/remove_image/`,
    data: snakeCase(payload),
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getUserDemog(token: string): Promise<any> {
  const data = await apiFetch({
    path: `/reviewer/`,
    baseURL: baseReviewURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data
}

export async function updateUserDemog(token: string, dataPayload: ReviewerApi): Promise<any> {
  const data = await apiFetch({
    path: `/reviewer/modify/`,
    baseURL: baseReviewURL,
    method: 'POST',
    noOrigin: true,
    ...(token && { authToken: token }),
    data: snakeCase(dataPayload),
  })
  return data
}

export async function reviewSubmitApi(token: string, reviewId: string) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/submit/`,
    data: {},
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function reviewDeleteApi(token: string, reviewId: string) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/delete/`,
    data: {},
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function toggleHelpfulReview(token: string, reviewId: string) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/toggle_helpful/`,
    data: {},
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function reportReview(token: string, reviewId: string, payload: any) {
  return await apiFetch({
    method: 'POST',
    path: `/review/${reviewId}/report/`,
    data: snakeCase(payload),
    ...(token && { authToken: token }),
    baseURL: baseReviewURL,
    noOrigin: true,
  })
}

export async function getGoogleReviews() {
  return await apiFetch({
    path: `/maps/api/place/details/json?place_id=ChIJA-xYp9pbwokRwWT9YMEXBvA&fields=name,reviews,rating,formatted_address&key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}`,
    baseURL: 'https://maps.googleapis.com',
    noOrigin: true,
  })
}
