import Image from 'next/image'
import { NextPage } from 'next/types'
import { segment } from 'src/lib/Segments'

import Layout from '@components/layouts'
import img404 from '@public/404-img.svg'
import { BiRightArrowAlt } from '@react-icons/all-files/bi/BiRightArrowAlt'
import { useAppSelector } from '@redux/hooks'

export const Custom500: NextPage = () => {
  const { currentCity } = useAppSelector((state) => state.currentUser)

  return (
    <Layout title="Not Found">
      <div className="mx-4 mt-8 mb-20 max-w-screen-lg grid-cols-2 gap-6 sm:my-12 sm:mx-auto sm:grid">
        <div className="mb-12 sm:mb-0">
          <div className="text-2xl font-semibold text-dark-900">Oh no, something is broken…</div>
          <div className="my-4 font-semibold text-dark-900">
            Our maintenance team is working hard to fix the problem ASAP! Meanwhile, you can do the
            following:
          </div>
          <a
            className="mt-4 flex text-sm text-bluegray-400 underline"
            onClick={() => segment.error_500_write_review()}
            href="/review"
          >
            1. Review your buildings (anywhere in the US)
            <BiRightArrowAlt size={20} className="ml-2" />
          </a>
          {currentCity.id === 'nyc' && (
            <a
              className="mt-4 flex text-sm text-bluegray-400 underline"
              onClick={() => segment.error_500_explore_listings()}
              href="/listings"
            >
              2. Explore listings
              <BiRightArrowAlt size={20} className="ml-2" />
            </a>
          )}
          <a
            className="mt-4 flex text-sm text-bluegray-400 underline"
            onClick={() => segment.error_500_resources()}
            href="/blogs"
          >
            {currentCity.id === 'nyc' ? '3' : '2'}. Explore rent tips & resources
            <BiRightArrowAlt size={20} className="ml-2" />
          </a>
        </div>
        <Image src={img404} alt="404" unoptimized />
      </div>
    </Layout>
  )
}

export default Custom500
