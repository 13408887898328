import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'
import { UnitApi } from 'src/common/types'

import { newProfile } from '@localtypes/Store'

import { apiFetch } from './Api'

const {
  publicRuntimeConfig: { BACKEND_API_LISTING, BACKEND_API_PROFILE },
} = getConfig()

const baseListingURL = BACKEND_API_LISTING
const baseProfileUrl = BACKEND_API_PROFILE

export async function newSaveUnit(token: string, unitSlug: string, save = true): Promise<void> {
  return await apiFetch({
    path: `/unit/${unitSlug}/save/`,
    method: save ? 'POST' : 'DELETE',
    authToken: token,
    baseURL: baseListingURL,
    noOrigin: true,
  })
}

export async function getNewSavedUnits(token: string): Promise<{ results: UnitApi[] }> {
  const data = await apiFetch({
    path: `/unit/saved/`,
    baseURL: baseListingURL,
    noOrigin: true,
    authToken: token,
  })
  return data.results
}

export async function getNewContactedUnits(token: string): Promise<{ results: UnitApi[] }> {
  const data = await apiFetch({
    path: `/unit/interested/`,
    baseURL: baseListingURL,
    noOrigin: true,
    authToken: token,
  })
  return data.results
}

export async function submitNewInterest(token: string, data: any, unitSlug: string): Promise<void> {
  return await apiFetch({
    path: `/unit/${unitSlug}/interest/`,
    method: 'POST',
    authToken: token,
    baseURL: baseListingURL,
    data: snakeCase(data),
    noOrigin: true,
  })
}

export async function getNewProfile(token: string): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/`,
    authToken: token,
    baseURL: baseProfileUrl,
    noOrigin: true,
  })
}

export async function updateNewProfile(token: string, data: any): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/modify/`,
    method: 'POST',
    authToken: token,
    baseURL: baseProfileUrl,
    data: snakeCase(data),
    noOrigin: true,
  })
}

export async function deleteNewProfile(token: string): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/delete/`,
    method: 'POST',
    authToken: token,
    baseURL: baseProfileUrl,
    noOrigin: true,
  })
}

export async function verifyEmail(token: string): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/send_verification_email/`,
    method: 'POST',
    authToken: token,
    baseURL: baseProfileUrl,
    noOrigin: true,
  })
}

export async function resetPasswordEmail(data): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/send_password_reset_email/`,
    method: 'POST',
    baseURL: baseProfileUrl,
    data: snakeCase(data),
    noOrigin: true,
  })
}

export async function verifyPhone(token: string): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/verify_phone/`,
    method: 'POST',
    authToken: token,
    baseURL: baseProfileUrl,
    noOrigin: true,
    headers: {
      platform: 'web',
    },
  })
}

export async function unlinkPhone(token: string): Promise<newProfile> {
  return await apiFetch({
    path: `/profile/unlink_phone/`,
    method: 'POST',
    authToken: token,
    baseURL: baseProfileUrl,
    noOrigin: true,
    headers: {
      platform: 'web',
    },
  })
}
