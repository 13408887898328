import { getSubscriptionPrices } from 'src/data/RentalReport'

import { Buildings, Contacts } from '../../data'
import { miscSlice } from '../reducers/misc'

export const fetchTopRatedBuildings = (pageSize: number, city: string) => async (dispatch) => {
  const buildings = await Buildings.getBuildingsTopRated(pageSize, city)
  dispatch(miscSlice.actions.setTopRatedBuildings(buildings.results))
}

export const fetchTopReviewedBuildings = (pageSize: number, city: string) => async (dispatch) => {
  const buildings = await Buildings.getBuildingsTopReviewed(pageSize, city)
  dispatch(miscSlice.actions.setTopReviewedBuildings(buildings.results))
}

export const fetchTopRatedContacts = (pageSize: number, city: string) => async (dispatch) => {
  const contacts = await Contacts.getContactsTopRated(pageSize, city)
  dispatch(miscSlice.actions.setTopRatedContacts(contacts.results))
}

export const fetchTopReviewedContacts = (pageSize: number, city: string) => async (dispatch) => {
  const contacts = await Contacts.getContactsTopReviewed(pageSize, city)
  dispatch(miscSlice.actions.setTopReviewedContacts(contacts.results))
}

export const setSubscriptionPrices = () => async (dispatch) => {
  const response = await getSubscriptionPrices()
  await dispatch(miscSlice.actions.setSubscriptionPrices(response))
}
