import { ReviewerApi, UnitApi } from 'src/common/types'
import { getUserDemog, getUserReviews, updateUserDemog } from 'src/data/Review'
import { getNewProfile, newSaveUnit, updateNewProfile } from 'src/data/newUserProfile'

import { AuthPrompts } from '@components/auth/AuthPrompts'
import { City } from '@localtypes/Store'
import { ReviewStatusType, Tenancy, TenantApplication } from '@openigloo/common'
import { searchListingSlice } from '@redux/reducers/searchListing'
import { addListener } from '@reduxjs/toolkit'

import { Applications } from '../../data'
import { segment } from '../../lib/Segments'
import { userSlice } from '../reducers/user'
import { AppState, Store } from '../store'

export const setIsLoggedIn = (val: boolean) => async (dispatch) => {
  dispatch(userSlice.actions.setIsLoggedIn(val))
  dispatch(searchListingSlice.actions.setShowListingsLoader(false))
}

export const setCurrentUserProfile = (token: string) => async (dispatch) => {
  let userProfile = await getNewProfile(token)
  await dispatch(userSlice.actions.setUserProfile(userProfile))

  const newInterestPhone = Store.getState().currentUser.newInterestPhone
  if (newInterestPhone && newInterestPhone !== userProfile?.phone) {
    userProfile = await dispatch(editNewProfile(token, { phone: newInterestPhone }))
  }

  dispatch(userSlice.actions.setToken(token))
  const consentEnabled = Store.getState().currentUser.consentEnabled
  if (consentEnabled && !userProfile.isPhoneVerified && userProfile?.phone?.length >= 8) {
    await dispatch(userSlice.actions.setTriggerConsent(true))
  }
  dispatch(userSlice.actions.setIsLoggedIn(true))
  segment.setUser(userProfile.uid, userProfile.email)
  dispatch(searchListingSlice.actions.setShowListingsLoader(false))
}

export const fetchCurrentUserProfile = (token: string) => async (dispatch) => {
  const userProfile = await getNewProfile(token)
  dispatch(userSlice.actions.setUserProfile(userProfile))
}

export const setCurrentUserModifyProfile = (token: string, data: any) => async (dispatch) => {
  let userProfile = await updateNewProfile(token, data)
  await dispatch(userSlice.actions.setUserProfile(userProfile))

  const newInterestPhone = Store.getState().currentUser.newInterestPhone
  if (newInterestPhone && newInterestPhone !== userProfile?.phone) {
    userProfile = await dispatch(editNewProfile(token, { phone: newInterestPhone }))
  }

  dispatch(userSlice.actions.setToken(token))
  const consentEnabled = Store.getState().currentUser.consentEnabled
  if (consentEnabled && !userProfile.isPhoneVerified && userProfile?.phone?.length >= 8) {
    await dispatch(userSlice.actions.setTriggerConsent(true))
  }
  dispatch(userSlice.actions.setIsLoggedIn(true))
  segment.setUser(userProfile.uid, userProfile.email)
  dispatch(searchListingSlice.actions.setShowListingsLoader(false))
}

export const logoutCurrentUser = () => (dispatch) => {
  dispatch(userSlice.actions.reset())
  dispatch(setAuthRequired(false))
}

export const setReviewStatus = (tenancyId: string, status: ReviewStatusType) => (dispatch) => {
  dispatch(userSlice.actions.updateTenancyReview({ tenancyId, field: 'status', value: status }))
}

export const updateCurrentUserTenancies = (data: Tenancy, id: string) => async (dispatch) => {
  dispatch(userSlice.actions.updateTenancies({ tenancy: data, id }))
}

export const saveUnit =
  (unit: UnitApi, save: boolean) => async (dispatch, getState: () => AppState) => {
    const {
      currentUser: { token },
    } = getState()
    await newSaveUnit(token, unit.slug, save)
    if (save) dispatch(userSlice.actions.addSavedUnit(unit))
    else dispatch(userSlice.actions.removeSavedUnit(unit))
  }

export const setAuthRequired =
  (
    val: boolean,
    emailPrefill = '',
    prompt: keyof typeof AuthPrompts = 'default',
    phonePrefill = ''
  ) =>
  (dispatch) => {
    dispatch(
      userSlice.actions.setAuthRequired({
        loginRequired: val,
        emailPrefill,
        onDismissURL: null,
        prompt,
        phonePrefill,
      })
    )
  }

// export const requireLogin = (
//   onSuccess: () => void | null = null,
//   onDismiss: () => void | null = null,
//   emailPrefill = '',
//   prompt: keyof typeof AuthPrompts = 'default',
//   handleCallbackOn?: string | null,
//   phonePrefill = ''
// ) => {
//   const isLoggedIn = Store.getState().currentUser.isLoggedIn
//   if (isLoggedIn) {
//     if (onSuccess) {
//       onSuccess()
//     }
//   } else {
//     Store.dispatch(setAuthRequired(true, emailPrefill, prompt, phonePrefill))
//     if (onSuccess) {
//       setAuthSuccessCallback(onSuccess, handleCallbackOn)
//     }
//     if (onDismiss) {
//       setAuthDismissCallback(onDismiss)
//     }
//   }
// }

export const requireLogin = (
  onSuccess: () => void | null = null,
  onDismiss: () => void | null = null,
  emailPrefill = '',
  prompt: keyof typeof AuthPrompts = 'default',
  handleCallbackOn?: string | null,
  phonePrefill = ''
) => {
  // console.log('🟢 requireLogin called');

  const state = Store.getState()
  const isLoggedIn = state.currentUser.isLoggedIn
  const triggerConsent = state.currentUser.triggerConsent
  const consentEnabled = state.currentUser.consentEnabled

  // console.log('🔎 Initial Redux State:', { isLoggedIn, triggerConsent, consentEnabled });

  const executeOnSuccess = () => {
    if (onSuccess) {
      // console.log("✅ Executing onSuccess function");
      onSuccess()
    }
  }

  const waitForTriggerConsentToDisable = (callback: () => void) => {
    // console.log('🟡 Waiting for triggerConsent to be false...');
    const unsubscribe = Store.subscribe(() => {
      const newState = Store.getState()
      if (!newState.currentUser.triggerConsent) {
        // console.log('✅ triggerConsent is now false, executing onSuccess');
        unsubscribe()
        callback()
      }
    })
  }

  if (isLoggedIn) {
    // console.log('🟡 User is logged in');

    if (!consentEnabled) {
      // console.log('🟢 Consent is NOT required, executing onSuccess immediately');
      executeOnSuccess()
      return
    }

    if (consentEnabled && !triggerConsent) {
      // console.log('🟢 Trigger Consent False, executing onSuccess immediately');
      executeOnSuccess()
      return
    }

    // console.log('🟡 Consent is required and waiting for triggerConsent to be false...');
    waitForTriggerConsentToDisable(executeOnSuccess)
  } else {
    // console.log('🔵 User is not logged in, requiring authentication...');
    Store.dispatch(setAuthRequired(true, emailPrefill, prompt, phonePrefill))

    if (onSuccess) {
      setAuthSuccessCallback(() => {
        // console.log('🔵 User successfully logged in');

        const newState = Store.getState()

        // ✅ If consent is NOT required, execute immediately
        if (!newState.currentUser.consentEnabled) {
          // console.log('🟢 Consent is NOT required after login, executing onSuccess');
          executeOnSuccess()
          return
        }

        // ✅ If triggerConsent is already false, execute immediately
        if (!newState.currentUser.triggerConsent) {
          // console.log('🟢 triggerConsent already resolved after login, executing onSuccess');
          executeOnSuccess()
          return
        }

        // ✅ Otherwise, wait for triggerConsent to be false
        // console.log('🟡 Waiting for triggerConsent after login...');
        waitForTriggerConsentToDisable(executeOnSuccess)
      }, handleCallbackOn)
    }

    if (onDismiss) {
      setAuthDismissCallback(onDismiss)
    }
  }
}

// export const setAuthSuccessCallback = (callback: () => void, handleCallbackOn: string) => {
//   Store.dispatch(
//     addListener({
//       predicate: (action, currentState: AppState, previousState: AppState) => {
//         if (
//           handleCallbackOn === 'hasReviewerAccess' &&
//           (currentState.currentUser.reviewerData?.hasReviewerAccess ||
//             currentState.currentUser.explorerData?.accessType)
//         ) {
//           return false
//         }
//         if (action.type === userSlice.actions.setToken.type) {
//           return (
//             (previousState.currentUser.token === null || previousState.currentUser.token === '') &&
//             currentState.currentUser.token?.length > 0
//           )
//         }
//         return false
//       },
//       effect: callback,
//     })
//   )
// }

export const setAuthSuccessCallback = (callback: () => void, handleCallbackOn?: string | null) => {
  Store.dispatch(
    addListener({
      predicate: (action, currentState: AppState, previousState: AppState) => {
        // console.log('🔍 Checking login state change...')

        // If handleCallbackOn is 'hasReviewerAccess', prevent execution
        if (
          handleCallbackOn === 'hasReviewerAccess' &&
          (currentState.currentUser.reviewerData?.hasReviewerAccess ||
            currentState.currentUser.explorerData?.accessType)
        ) {
          // console.log('🔹 Blocking callback due to hasReviewerAccess.')
          return false
        }

        // Detect when the user successfully logs in
        const wasLoggedOut = !previousState.currentUser.isLoggedIn
        const isNowLoggedIn = currentState.currentUser.isLoggedIn

        if (wasLoggedOut && isNowLoggedIn) {
          // console.log('✅ User logged in, triggering success callback.')
          return true
        }

        return false
      },
      effect: callback,
    })
  )
}

export const setAuthDismissCallback = (callback: () => void) => {
  return Store.dispatch(
    addListener({
      predicate: (action, currentState: AppState, previousState: AppState) => {
        if (action.type === userSlice.actions.setAuthRequired.type) {
          return (
            previousState.currentUser.authRequired.loginRequired &&
            !currentState.currentUser.authRequired.loginRequired
          )
        }
        return false
      },
      effect: (_action, _listenerApi) => {
        callback()
      },
    })
  )
}

export const editNewProfile = (token: string, data: any) => async (dispatch) => {
  const userProfile = await updateNewProfile(token, data)
  dispatch(userSlice.actions.setUserProfile(userProfile))
  return userProfile
}

export const setCurrentUserUnitApplications = (token: string) => async (dispatch) => {
  const apps = await Applications.getAllApplications(token)
  dispatch(
    userSlice.actions.setUnitApplications(
      apps
        ?.reverse()
        ?.sort((a, b) => new Date(b?.atUpdated)?.getTime() - new Date(a?.atUpdated)?.getTime())
    )
  )
}

export const addUserUnitApplication =
  (application: TenantApplication) => async (dispatch, getState: () => AppState) => {
    const {
      currentUser: { unitApplications },
    } = getState()
    if (!unitApplications.map((u) => u.id).includes(application.id)) {
      dispatch(userSlice.actions.setUnitApplications([...unitApplications, application]))
    }
  }

export const setCurrentCity = (city: City) => async (dispatch) => {
  dispatch(userSlice.actions.setCurrentCity(city))
}

export const setReviewerData = (token: string) => async (dispatch) => {
  const data = await getUserDemog(token)
  dispatch(userSlice.actions.setReviewerData(data))
}
export const setUserReviews = (token: string) => async (dispatch) => {
  const data = await getUserReviews(token)
  dispatch(userSlice.actions.setReviewData(data))
}

export const updateReviewerData = (token: string, newDemog: ReviewerApi) => async (dispatch) => {
  const data = await updateUserDemog(token, newDemog)
  await dispatch(userSlice.actions.setReviewerData(data))
}
