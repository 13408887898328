import { SearchListingState } from '@localtypes/Store'
import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_MAP_ZOOM } from '../../common/Constants'

export const initialState: SearchListingState = {
  currentFilter: {
    minPrice: null,
    maxPrice: null,
    noBedrooms: [],
    noBathrooms: null,
    isPetsAllowed: null,
    hasWasherDryer: null,
    hasPrivateOutdoorSpace: null,
    hasDishwasher: null,
    hasElevator: null,
    hasDoorman: null,
    hasLaundryRoom: null,
    hasOutdoorSpace: null,
    hasParking: null,
    hasPool: null,
    hasGym: null,
    amenityIds: [],
    neighbourhoodIds: [],
    isNoOneYearBedbugHistory: null,
    isNoEvictionHistory: null,
    isNoPendingLitigation: null,
    isNoOpenViolations: null,
    isNoFee: null,
    isRentStabilized: null,
    minRating: null,
    boroughs: [],
    minLatitude: null,
    maxLatitude: null,
    minLongitude: null,
    maxLongitude: null,
    isTopRated: null,
    buildingId: null,
    buildingAddress: null,
    cityId: null,
    addressSlug: null,
    income: null,
    creditScoreRange: null,
    hasGuarantor: null,
    noOccupants: null,
    targetMoveInMonths: null,
  },
  currentFilterResults: [],
  currentPage: 1,
  currentFilterResultsCount: 0,
  isFetchingResults: true,
  nextFilterLink: null,
  zoom: DEFAULT_MAP_ZOOM,
  chosenListing: null,
  recenter: true,
  amenities: [],
  currentFilterSort: null,
  focusedListings: [],
  savedSearch: null,
  isSavedSearch: false,
  showListings: false,
  showListingsLoader: true,
  customMapHeader: false,
}

export const searchListingSlice = createSlice({
  name: 'searchListing',
  initialState,
  reducers: {
    setCurrentResults: (state, action) => {
      state.currentFilterResults = action.payload
    },
    setCurrentFilter: (state, action) => {
      state.currentFilter = { ...state.currentFilter, [action.payload.key]: action.payload.value }
      localStorage.setItem('filter', JSON.stringify(state.currentFilter))
    },
    resetCurrentFilter: (state) => {
      state.currentFilter = {
        ...initialState.currentFilter,
        income: state.currentFilter.income,
        creditScoreRange: state.currentFilter.creditScoreRange,
        hasGuarantor: state.currentFilter.hasGuarantor,
        noOccupants: state.currentFilter.noOccupants,
        targetMoveInMonths: [],
      }
      localStorage.setItem('filter', JSON.stringify(state.currentFilter))
    },
    setCurrentFilterStorage: (state, action) => {
      state.currentFilter = action.payload
      localStorage.setItem('filter', JSON.stringify(state.currentFilter))
    },
    setShowListings: (state, action) => {
      state.showListings = action.payload
    },
    setShowListingsLoader: (state, action) => {
      state.showListingsLoader = action.payload
    },
    setZoom: (state, action) => {
      state.zoom = action.payload
    },
    setChosenListing: (state, action) => {
      state.chosenListing = action.payload
    },
    setRecenter: (state, action) => {
      state.recenter = action.payload
    },
    setCurrentFilterSort: (state, action) => {
      state.currentFilterSort = action.payload
    },
    setFocusedListings: (state, action) => {
      state.focusedListings = action.payload
    },
    setSavedSearch: (state, action) => {
      state.savedSearch = action.payload
    },
    setIsSavedSearch: (state, action) => {
      state.isSavedSearch = action.payload
    },
    setCustomMapHeader(state, action) {
      state.customMapHeader = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    // .addCase(setCurrentFilter.fulfilled, (state, action: any) => {
    //   return { ...state, currentFilter: action.payload }
    // })
  },
})

export default searchListingSlice.reducer
