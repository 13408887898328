import React from 'react'

import OIIcon from '@components/icons'

import { OIPillIconSize, OIPillProps, OIPillSize, OIPillStatus, OIPillType } from './interface'

const getTypeColors = (type) => {
  switch (type) {
    case OIPillType.PRIMARY:
      return 'bg-primary text-textLight border-bluegray-100'
    case OIPillType.PRIMARY_DARK:
      return 'bg-primaryDark text-textLight border-blue-25'
    case OIPillType.SECONDARY:
      return 'bg-blue-25 text-blue-200 border-secondaryBorder'
    case OIPillType.TERTIARY:
      return 'bg-tertiary text-tertiaryText border-border'
    case OIPillType.RECTANGLE:
      return 'bg-light-10 shadow rounded-none border-none text-dark-900 !rounded'
    default:
      return 'bg-primary text-white border-primary'
  }
}

const getStatusStyles = (buttonStatus) => {
  switch (buttonStatus) {
    case OIPillStatus.PENDING:
    case OIPillStatus.DISABLED:
      return 'bg-disabledBg text-white border-disabledBg'
    case OIPillStatus.SUCCESS:
      return 'bg-successBg text-green-500 border-successPillBorder'
    case OIPillStatus.SUCCESSRECTANGLE:
      return 'bg-successBg shadow rounded-none border-none text-dark-900 !rounded'
    case OIPillStatus.ERROR:
    case OIPillStatus.IMPORTANT:
      return 'bg-errorBg text-red-500 border-errorPillBorder'
    case OIPillStatus.ATTENTION:
      return 'bg-yellow-75 text-blue-200 border-warningPillBorder'
    case OIPillStatus.ATTENTIONRECTANGLE:
      return 'bg-yellow-75 shadow rounded-none border-none text-dark-900 !rounded'
    default:
      return 'bg-successBg text-green-500 border-successPillBorder'
  }
}

const getSizeStyles = (size) => {
  switch (size) {
    case OIPillSize.SMALL:
      return 'px-2 py-1 text-xxs'
    case OIPillSize.LARGE:
      return 'px-4 py-1 text-xs'
    default:
      return ''
  }
}

const BASE_STYLES = 'inline-flex items-center rounded-full border'

const getTypeStyles = (type, status) => {
  return status ? getStatusStyles(status) : getTypeColors(type)
}

export const OIPill = ({
  type = OIPillType.PRIMARY,
  label = '',
  status = '',
  size = OIPillSize.SMALL,
  icon = '',
  iconSize = OIPillIconSize.MEDIUM,
  iconPositionLeft = true,
  style = '',
  ...props
}: OIPillProps) => {
  return (
    <div
      className={` 
      ${BASE_STYLES} 
      ${getTypeStyles(type, status)}
      ${size ? getSizeStyles(size) : 'px-2 py-1 text-xxs md:px-4 md:text-xs'}
      ${style}
       `}
      {...props}
    >
      {icon && (
        <span className={`${iconPositionLeft ? 'order-1 pr-1 md:pr-2' : 'order-2'}`}>
          {<OIIcon name={icon} size={iconSize} />}
        </span>
      )}
      {label && (
        <span className={`${iconPositionLeft ? 'order-2' : 'order-1 pr-1 md:pr-2'}`}>{label}</span>
      )}
    </div>
  )
}

export const OIPrimaryPill = ({ ...rest }: OIPillProps) => {
  return <OIPill type={OIPillType.PRIMARY} {...rest} />
}
export const OIPrimaryDarkPill = ({ ...rest }: OIPillProps) => {
  return <OIPill type={OIPillType.PRIMARY_DARK} {...rest} />
}
export const OISecondaryPill = ({ ...rest }: OIPillProps) => {
  return <OIPill type={OIPillType.SECONDARY} {...rest} />
}
export const OITertiaryPill = ({ ...rest }: OIPillProps) => {
  return <OIPill type={OIPillType.TERTIARY} {...rest} />
}

export const OIStatusPill = ({
  label = 'Status Pill',
  type = OIPillType.PRIMARY_DARK,
  status = OIPillStatus.PENDING,
  ...rest
}: {
  label?: string
  type?: OIPillType
  status?: OIPillStatus | ''
}) => {
  return <OIPill type={type} status={status} label={label} {...rest} />
}

export const OITextIconPill = ({ label, icon, iconSize = OIPillIconSize.MEDIUM, ...rest }) => {
  return <OIPill label={label} icon={icon} iconSize={iconSize} {...rest} />
}

export default OIPill
export * from './interface'
