export interface OIRadioProps {
  label: string | number | any
  value: string | number
  selectedValue: string | number
  onChange: (option) => void
  labelStyle?: string
  disabled?: boolean
}
export const OIRadio = ({
  label,
  value,
  selectedValue,
  onChange,
  labelStyle,
  disabled = false,
}: OIRadioProps) => {
  const bg = selectedValue === value ? 'bg-primarylight' : 'bg-white border border-mid-60'
  return (
    <div
      className="inline-flex items-center space-x-4 text-base"
      onClick={() => (disabled ? {} : onChange(value))}
    >
      <div
        className={`relative flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-xl ${bg} cursor-pointer`}
      >
        <div className={`relative h-1.5 w-1.5 rounded-full bg-white`} />
      </div>
      <span className={`text-primarytext ${labelStyle || ''}`}>{label}</span>
    </div>
  )
}

export default OIRadio
