import { getDeepLink } from 'src/data/ActionCards'

import { ListingInterestBody, OpenListing } from '@openigloo/common'
import { AppState } from '@redux/store'

import { activitySlice } from '../reducers/activity'

export const addToVisitedListings = (listing: OpenListing) => (dispatch) => {
  dispatch(activitySlice.actions.addVisitedListing(listing))
}

export const setLastListingInterestBody = (body: ListingInterestBody) => (dispatch) => {
  dispatch(activitySlice.actions.setLastListingInterestBody(body))
}

export const setShowShareOptions = (show) => (dispatch) => {
  dispatch(activitySlice.actions.setShowShareOptions(show))
}

export const setReferralLink = () => async (dispatch) => {
  const link = await getDeepLink()
  await dispatch(activitySlice.actions.setReferralLink(link))
}

export const resetReferralLink = () => async (dispatch) => {
  dispatch(activitySlice.actions.resetReferralLink())
}

export const resetVisitedPagesCount = () => (dispatch) => {
  dispatch(activitySlice.actions.setVisitedPagesCount(1))
}

export const addVisitedPagesCount = () => (dispatch, getState: () => AppState) => {
  const { visitedPagesCount } = getState().userActivity
  dispatch(activitySlice.actions.setVisitedPagesCount(visitedPagesCount + 1))
}

export const showReferralPopup = () => (dispatch, getState: () => AppState) => {
  const { referralPopup } = getState().userActivity
  dispatch(
    activitySlice.actions.setReferralPopup({
      ...referralPopup,
      shownCount: referralPopup.shownCount + 1,
      visible: true,
    })
  )
}

export const hideReferralPopup = () => (dispatch, getState: () => AppState) => {
  const { referralPopup } = getState().userActivity
  dispatch(activitySlice.actions.setReferralPopup({ ...referralPopup, visible: false }))
}

export const setPopupConverted = () => (dispatch, getState: () => AppState) => {
  const { referralPopup } = getState().userActivity
  dispatch(activitySlice.actions.setReferralPopup({ ...referralPopup, converted: true }))
}

export const resetReferralPopup = () => (dispatch) => {
  dispatch(
    activitySlice.actions.setReferralPopup({
      name: 'referral',
      shownCount: 0,
      converted: false,
      visible: false,
    })
  )
}

export const toggleNavMenu = () => (dispatch, getState: () => AppState) => {
  const { showMobileNavMenuItems } = getState().userActivity
  dispatch(activitySlice.actions.setShowMobileNavMenuItems(!showMobileNavMenuItems))
}

export const alignMenuNavWithModalTitle = (val) => (dispatch) => {
  dispatch(activitySlice.actions.setAlignMenuNavWithModalTitle(val))
}
