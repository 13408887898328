export interface OITextProps extends React.HTMLAttributes<HTMLDivElement> {
  children: any
  color?: string
  weight?: OIFontWeights
  size?: OIFontSizes | string
  style?: any
  type?: OITextType | ''
  customTextStyles?: string
}

export interface OILinkProps extends OITextProps {
  link: string
}

export enum OIFontWeights {
  THIN = 'thin',
  EXTRA_LIGHT = 'extralight',
  LIGHT = 'light',
  NORMAL = 'normal',
  MEDIUM = 'medium',
  SEMI_BOLD = 'semibold',
  BOLD = 'bold',
  EXTRA_BOLD = 'extrabold',
  BLACK = 'black',
}

export enum OIFontSizes {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl',
  ONE_FIVE_XL = '1.5xl',
  TWO_XL = '2xl',
  THREE_XL = '3xl',
  FOUR_XL = '4xl',
  FIVE_XL = '5xl',
  SIX_XL = '6xl',
  EIGHT_XL = '8xl',
}

export enum OIFontColors {
  PRIMARY = 'dark-900',
  SECONDARY = 'dark-500',
}

export enum OITextType {
  BODY = 'body',
  SUBTITLE = 'subtitle',
  HEADLINE1 = 'headline1',
  HEADLINE2 = 'headline2',
  HEADLINE3 = 'headline3',
  HEADLINE4 = 'headline4',
  HEADLINE5 = 'headline5',
  HEADLINE6 = 'headline6',
}
