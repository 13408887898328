import AnalyticJS from 'analytics'

import segmentPlugin from '@analytics/segment'
import EventAnalytics from '@openigloo/common'
import { inIframe } from '@utility/Utilities'

const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY
const SEGMENT_APP = 'web-v2'

type EventAnalyticsArgs = {
  segmentKey: string
  segmentApp: string
}

class WebEventAnalytics extends EventAnalytics {
  [x: string]: any
  constructor(args: EventAnalyticsArgs) {
    const instance = AnalyticJS({
      app: args.segmentApp,
      debug: true,
      plugins: [
        segmentPlugin({
          writeKey: args.segmentKey,
        }),
      ],
    })
    super(instance)
  }
}

const originalSegment = !inIframe()
  ? new WebEventAnalytics({
      segmentApp: SEGMENT_APP,
      segmentKey: SEGMENT_KEY,
    })
  : {}

const handler = {
  get: function (target, key) {
    return function (...args) {
      try {
        target[key].apply(this, args)
      } catch (e) {
        console.log(e)
      }
    }
  },
}

// Proxy function to add try catch to every segment call
export const segment = new Proxy(originalSegment, handler)

export function setUserAgentType() {
  const city = localStorage?.getItem('city') ?? null
  segment.annotate({
    platform: SEGMENT_APP,
    selected_city: city ? JSON.parse?.(city)?.name : 'New York City',
  })
}
