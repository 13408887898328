import { UserProfileType } from '@openigloo/common'

export const LINKS = {
  tos: 'https://www.openigloo.com/terms-of-use',
  privacyPolicy: 'https://www.openigloo.com/privacy-policy',
  communityGuideline: 'https://www.openigloo.com/community-guidelines',
  faqs: 'https://www.openigloo.com/faqs',
}
export const TITLE_MAX_LENGTH = 80
export const CURRENT_CITY = 'nyc'
export const MASKED_VALUE = 'XXX'
export const TBD_VALUE = 'TBD'
export const ReviewRatingKeys = {
  waterPressure: 'water-pressure',
  cleanliness: 'cleanliness',
  pestControl: 'pest-control',
  heat: 'heat',
  noiseLevels: 'noise-levels',
  ownerResponsiveness: 'owner-responsiveness',
  neighbors: 'neighbors',
  garbageManagement: 'garbage-management',
}

export const REFERRAL_LINK_PREFIX = 'https://openigloo.app'
export const WEBSITE_URL = 'https://www.openigloo.com'

export const USERS_COLLECTION = 'users'
export const REFERRAL_LINK_CODES_COLLECTION = 'referralLinkCodes'
export const SAVED_BUILDINGS_COLLECTION = 'savedBuildings'
export const SAVED_CONTACTS_COLLECTION = 'savedContacts'
export const SAVED_LISTINGS_COLLECTION = 'savedListings'
export const SAVED_RESOURCES_COLLECTION = 'savedResources'
export const HELPFUL_REVIEWS_COLLECTION = 'helpfulReviews'
export const CITIES_COLLECTION = 'cities'
export const BOROUGHS_COLLECTION = 'boroughs'
export const NEIGHBORHOODS_COLLECTION = 'neighborhoods'
export const ZIP_CODES_COLLECTION = 'zipCodes'
export const BUILDINGS_COLLECTION = 'buildings'
export const CONTACTS_COLLECTION = 'contacts'
export const LISTINGS_COLLECTION = 'listings'
export const TENANTS_COLLECTION = 'tenants'
export const OPEN_LISTINGS_COLLECTION = 'openListings'
export const UNITS_COLLECTION = 'units'
export const RESOURCES_COLLECTION = 'resources'
export const RATING_TYPES_COLLECTION = 'ratingTypes'
export const RESPONSE_TYPES_COLLECTION = 'ratingTypes'
export const REVIEWS_COLLECTION = 'reviews'
export const RECENTLY_REVIEWED_BUILDINGS_COLLECTION = 'recentlyReviewedBuildings'
export const TENANCIES_COLLECTION = 'tenancies'
export const RESPONSES_COLLECTION = 'responses'
export const RATINGS_COLLECTION = 'ratings'
export const QUESTIONS_COLLECTION = 'questions'
export const ANSWERS_COLLECTION = 'answers'
export const OWNERSHIP_COLLECTION = 'ownership'
export const APPLICATIONS_COLLECTION = 'applications'
export const ASSOCIATESHIP_COLLECTION = 'associateship'
export const COLLABORATORS_COLLECTION = 'collaborators'
export const LISTING_FILTER_PAGE_SIZE = 15

export const DEFAULT_CITY = 'nyc'

export const REVIEW_STATUS_MAP = {
  PUBLISHED: 'published',
  REJECTED: 'rejected',
  PENDING: 'pending',
  DRAFT: 'draft',
}

export const TENANCY_STATUS_MAP = {
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
}

export const QUESTION_STATUS_MAP = {
  PUBLISHED: 'published',
  REJECTED: 'rejected',
}

export const OWNER_PROFILE_PATH = '/owner/profile'
export const OWNER_SETTINGS_PATH = '/owner/settings'
export const OWNER_BUILDINGS_PATH = '/owner/buildings'
export const OWNER_LISTINGS_PATH = '/owner/listings'
export const OWNER_MESSAGES_PATH = '/owner/messages'
export const OWNER_TENANTS_PATH = '/owner/tenants'
export const OWNER_APPLICATIONS_PATH = '/owner/applications'

export const STRIPE_LIVE_KEY =
  'pk_live_51InpHtLWBExMsHm1fVKDYJmkel0IRZZD70b8t2wVXQma33lHp9dd2CbIpUx3tvnijURYhjLUqb4GpR7638HVY4wX00yaqgEoXy'

export const STRIPE_TEST_KEY =
  'pk_test_51InpHtLWBExMsHm1T937MOgo3U0XkzvkdEFvWewSRip8WcPWu2Y0S4TMsPRMKnonotH0GFHyd5NEAFqCK70niyvZ00s8GNrMI0'

export const NYC_LAT = 40.7128
export const NYC_LNG = -74.006

export const CUSTOM_MAP_ID = '19594104e4506f8f'
export const DEFAULT_MAP_ZOOM = 13

export const CREDIT_SCORE_RANGES: UserProfileType['creditScoreRange'][] = [
  '300-599',
  '600-649',
  '650-699',
  '700-749',
  '750-850',
  'no_us_credit_score',
]

export const PRICE_FILTER_MAX_PRICE = null
export const PRICE_FILTER_MIN_PRICE = null

export const REVIEW_UPLOAD_EXAMPLES = [
  'Copy of your rental history (requested from DHCR)',
  'Photos of your apartment or building',
  'Examples of landlord/tenant correspondence',
  'Utility bills (remove any identifiers)',
  'Lead paint reports',
]

export const enum DocumentUploadType {
  TAX_RETURN_DOC = "Last Year's Tax Return",
  PAYMENT_HISTORY_DOC = '6-Month Rental Payment History Proof (e.g. voided checks; venmo screenshots)',
  CREDIT_CHECK_DOC = 'Credit Check (if you have your own credit report from the last 30 days, you can upload here)',
  OTHER_SUPPORTING_DOC = 'Other',
}

export const NYC_BOROUGHS = [
  { label: 'Manhattan', id: 'manhattan' },
  { label: 'Brooklyn', id: 'brooklyn' },
  { label: 'Bronx', id: 'the-bronx' },
  { label: 'Queens', id: 'queens' },
  { label: 'Staten Island', id: 'staten-island' },
]

export const enum PageMetaDescriptions {
  home = "Search 1 million+ addresses, read landlord reviews, access city-data, and find highly-rated apartments. openigloo helps renters avoid bedbugs and neglectful landlords. Don't sign a lease without vetting your building on openigloo.",
  listings = 'Browse apartment listings for rent in NYC on openigloo. Secure your ideal home with from reputable landlords and buildings.',
  review = 'Write a review of your current or past building',
}

export const REVIEW_DEMOG_FIELDS = ['ethnicity', 'gender', 'isCitizen', 'dob', 'hasDisability']

export const UNIT_PAGE_REVIEW_WALL_EXPERIMENT_ID = 'f6hoL9ofQAOSgV7YI_rX6w'

export const OPENIGLOO_SHARE_TITLE =
  'Check out openigloo! You can read and share building reviews, and browse available apartments.'

export const OPENIGLOO_SHARE_DESCRIPTION = ''

export const DAYJS_DAYSAGO_UPDATELOCALE = {
  relativeTime: {
    future: 'in %s',
    past: 'today',
    s: 'today',
    m: 'today',
    mm: 'today',
    h: 'today',
    hh: 'today',
    d: 'a day ago',
    dd: '%d days ago',
    M: 'a month ago',
    MM: '%d months ago',
    y: 'a year ago',
    yy: '%d years ago',
  },
}

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'

export const CACHE_DEFAULT = {
  ttl: 1000 * 60 * 60,
}

export const cityChoices = [
  {
    label: 'New York',
    slug: 'nyc',
  },
  {
    label: 'Boston',
    slug: 'boston',
  },
]

export const accessSubscriptionConstant = [
  {
    title: 'Building and owner open violations',
    free: true,
    paid: true,
  },
  {
    title: 'Renter Q&A',
    free: true,
    paid: true,
  },
  {
    title: 'Explore listings',
    free: true,
    paid: true,
  },
  {
    title: 'Building ratings',
    free: false,
    paid: true,
  },
  {
    title: 'Renter recommendations',
    free: false,
    paid: true,
  },
  {
    title: 'Rents and deposits',
    free: false,
    paid: true,
  },
  {
    title: 'Read reviews',
    free: false,
    paid: true,
  },
]

export const rent_stabilized = [
  {
    title: 'What is a rent-stabilized apartment?',
    description: `It generally applies to buildings of six or more units built before 1974 that are not subject to rent control. Over the years, some of the buildings may have become destabilized for a variety of reasons. Some buildings have a mix of both rent-stabilized and market rate apartments. But many new construction buildings in NYC also have rent-stabilized apartments in them. Developers receive tax abatements in exchange for making their new buildings stabilized. If you have a stabilized apartment, your <a class="text-bluegray-300 underline" href="https://www.openigloo.com/blogs/how-to-strategize-your-stabilized-lease-renewal">rent</a> can only go up a certain amount every year and you are entitled to a lease renewal. Your landlord HAS to renew your lease every year. The rent increase is set by the Rent Guidelines Board - they vote on the increase every June.`,
  },
  {
    title: 'How can I find a rent-stabilized apartment?',
    description:
      "There are nearly 1 million rent-stabilized apartments in NYC - almost 50% of all the housing stock. That means it's not impossible to get your hands on a rent-stabilized apartment. You can <a class='text-bluegray-300 underline' href='https://www.openigloo.com/listings'>browse rent-stabilized listing</a> on openigloo. You do not need to qualify to get a stabilized apartment. Anyone can rent one. A common misconception is that you can only find stabilized units in old or run down buildings. That's not true. Many new units on the market are stabilized and some of them even have high rents. Rent-stabilization doesn't mean cheap.",
  },
  {
    title: 'How can I find out if my apartment is a rent-stabilized unit?',
    description:
      "The only way to confirm 100% if your unit is regulated is to <a href='https://www.openigloo.com/blogs/find-rent-stabilized-apartment-new-york-city' class='text-bluegray-300 underline'>request your rental history from DHCR</a> - the state agency in charge of rent-regulation. DHCR will mail you a physical copy of your rent history to your address - only the existing tenant can request this. In the history you'll be able to see if your unit is stabilized and what the legal rent is. Make sure what you're paying matches with what's on the record. There are cases where tenants learn about overcharges. This is illegal and landlords are required to pay treble damages if they are caught doing this. Know the status of your apartment and <a href='https://portal.hcr.ny.gov/app/ask' class='text-bluegray-300 underline'>request your rental history!</a>",
  },
  {
    title: 'Can a landlord destabilize or deregulate apartments?',
    description:
      "Prior to a bunch of law changes in 2019, there were some pathways landlords could use to destabilize a unit. They could do renovations to deregulate a unit or if the apartment's rent hit a certain threshold, it would automatically become destabilized. All of that has changed. Unless a tax abatement is expiring (usually on new development buildings), it's nearly impossible for a unit to fall out of stabilization.",
  },
  {
    title: 'What is a preferential rent? Is this normal?',
    description:
      "Preferential rent is very normal and is when a landlord charges less than the legal rent. Let's say the legal rent on the apartment is $2,000 but they are only charging you $1,800 - that is a preferential rent, a better price! Landlords must register this preferential rent on the history and your increases should be based on the preferential rent not the legal rent. This wasn't the case before the 2019 law change. Landlords could increase your rent up to the legal rent amount even if they gave you a preferential rate the year before.",
  },
  {
    title: 'What is a rent-controlled apartment?',
    description:
      "Rent control is a policy that restricts increases on apartments in buildings built before 1947. Rent control in NYC is VERY rare and there are only about 16,000 rent-controlled units left in the city. To qualify for rent control, a tenant must have been continuously living in an apartment since July 1, 1971, or be a qualifying family member who succeeded the apartment (more on this later). This is why most rent-controlled tenants are elderly - they've been living in the same space since the 70s! Their rent is from the 70s too - sometimes just a couple of hundred dollars. <br/><br/> When a rent-controlled apartment becomes vacant, it automatically becomes 'rent stabilized', except in buildings with fewer than six units, where it is usually decontrolled. This is why the number of rent-controlled apartments is so few - when the tenant passes away or vacates, the unit falls into stabilization status. Unlike rent-stabilized apartments, rent-controlled tenants don't have a lease. They don't need one. They are a permanent tenant of that space - the landlord can never ask them to leave.",
  },
  {
    title: 'Can you inherit a rent-stabilized or rent-controlled apartment?',
    description:
      "Yes you can. See our <a href='https://www.tiktok.com/t/ZT8eUYd56/' class='text-bluegray-300 underline'>Tiktok explainer</a> on this! Regulated apartments have succession rights. This means that an apartment can be passed down only if the person inheriting the apartment has also been living there the previous 2 years. The requirement is only 1 year for persons over 62 or for people with disabilities. So for example, your grandparent (or great-grandparent) has a rent controlled or rent-stabilized apartment - as long as you're living there for 2 years before they vacate, you can claim your succession rights. But if it's a low rent apartment especially, be prepared to prove your residency (the landlord and likely lawyers will verify). Succession rights on rent-stabilized apartments have become less relevant since 2019 as a unit can no longer fall out of stabilization at vacancy. The landlord cannot increase the rent or destabilize the unit once someone vacates.",
  },
  {
    title: "How Using openigloo's NYC Rent Stabilized Map Can Help You Find The Right Apartment",
    description:
      "The New York rental market can be a complex landscape to explore. That's where tools like openigloo's interactive map come in handy. Designed to provide comprehensive coverage of rent-stabilized apartments throughout NYC, this tool offers detailed apartment listings and advanced search functionality tailored to your specific needs. <br/><br/>The unique feature of openigloo's map is its emphasis on affordability indicators and historical rent patterns. Designed to help renters, the map: <br/><br/><ul style='list-style-type:disc;'><li>Identifies affordable options quickly</li><li>Helps understand market dynamics</li><li>Is an invaluable resource for anyone seeking a rent-stabilized apartment in the city.</li></ul>",
  },
]

export const rent_stabilized_coordinates = {
  manhattan: {
    center: {
      lat: 40.74377300052899,
      lng: -73.98579408347284,
    },
    maxLat: 40.801121,
    minLat: 40.70271,
    maxLng: -73.941079,
    minLng: -74.021266,
  },
  brooklyn: {
    center: {
      lat: 40.660239,
      lng: -73.947698,
    },
    maxLat: 40.738961,
    minLat: 40.569663,
    maxLng: -73.855316,
    minLng: -73.974529,
  },
  queens: {
    center: {
      lat: 40.726833,
      lng: -73.819056,
    },
    maxLat: 40.795598,
    minLat: 40.644846,
    maxLng: -73.700488,
    minLng: -73.959759,
  },
  bronx: {
    center: {
      lat: 40.8577,
      lng: -73.864757,
    },
    maxLat: 40.910596,
    minLat: 40.791659,
    maxLng: -73.804231,
    minLng: -73.933241,
  },
  statenisland: {
    center: {
      lat: 40.580762,
      lng: -74.149482,
    },
    maxLat: 40.649019,
    minLat: 40.496365,
    maxLng: -74.053028,
    minLng: -74.205861,
  },
}

export const termsOfService = [
  {
    id: 'partiesToThisAgreement',
    title: 'Parties to this Agreement',
  },
  {
    id: 'theServices',
    title: 'The Services',
  },
  {
    id: 'eligibility',
    title: 'Eligibility',
  },
  {
    id: 'changesToThisAgreement',
    title: 'Changes to this Agreement',
  },
  {
    id: 'openiglooAccounts',
    title: 'Openigloo Accounts',
  },
  {
    id: 'verificationAndPrivacy',
    title: 'Verification and Privacy',
  },
  {
    id: 'prohibitedActivities',
    title: 'Prohibited Activities',
  },
  {
    id: 'intellectualProperty',
    title: 'Intellectual Property',
  },
  {
    id: 'trademarkNotices',
    title: 'Trademark Notices',
  },
  {
    id: 'consentToElectricCommunications',
    title: 'Consent To Electric Communications',
  },
  {
    id: 'sms',
    title: 'Short Message Service (“SMS”) Communications',
  },
  {
    id: 'disputeResolution',
    title: 'Dispute Resolution',
  },
  {
    id: 'applicableLawAndJurisdiction',
    title: 'Applicable Law and Jurisdiction',
  },
  {
    id: 'limitationOfLiability',
    title: 'Limitation of Liability',
  },
  {
    id: 'indemnification',
    title: 'Indemnification',
  },
  {
    id: 'disclaimerOfWarranties',
    title: 'Disclaimer of Warranties',
  },
  {
    id: 'miscellaneous',
    title: 'Miscellaneous',
  },
  {
    id: 'questions',
    title: 'Questions',
  },
]

export const rentStabilizedStatuses = [
  {
    title: 'MULTIPLE DWELLING A',
    description:
      'As defined under the Multiple Dwelling Law, a multiple dwelling building which is generally occupied as a permanent residence. The class includes such buildings as apartment houses, apartment hotels, maisonette apartments, and all other multiple dwellings except class B dwellings.',
  },
  {
    title: 'MULTIPLE DWELLING B',
    description:
      'A multiple dwelling which is occupied, as a rule, transiently, as the more or less temporary abode of individuals or families. This class includes such buildings as hotels, lodging houses, single room occupancies, boarding schools, furnished room houses, college and school dormitories.',
  },
  {
    title: '421-G',
    description:
      'The 421-G Tax Incentive is a tax exemption and abatement for conversion of commercial buildings to multiple dwellings in downtown Manhattan. 421-g is not available to projects that commenced conversion after 2006.',
  },
  {
    title: 'ROOMING HOUSE',
    description:
      'Generally, residential properties under Class B Multiple Dwellings, which include rooming houses contain units occupied by transient residents and are not required to have a kitchen or bathroom in each unit.',
  },
  {
    title: 'J-51',
    description:
      'A program governed by Sections 11-243 and 11-244 of the New York City Administrative Code (formerly numbered J-51) under which, in order to encourage development and rehabilitation property tax abatements and exemptions are granted. In consideration of receiving these tax abatements and at least for the duration of the abatements, the owner of these buildings agrees to place under rent stabilization, those apartments which would not otherwise be subject to rent stabilization (e.g., those in buildings with fewer than 6 apartments or buildings constructed after 12/31/73).',
  },
  {
    title: 'GARDEN COMPLEX',
    description:
      'Such complexes may consist of attached, detached, or semi-attached dwelling units, containing six or more housing accommodations having common facilities such as a sewer line, water main, or heating plant and operated as a unit under common ownership. They are subject to regulation even though Certificates of Occupancy were issued for portions of the complex as one or two family dwellings.',
  },
  {
    title: 'ARTICLES 14 & 15',
    description:
      'Under this article of the Private Housing Finance Law, buildings are required to register their rent regulated apartments.',
  },
  {
    title: 'ARTICLE 11',
    description:
      'Under this article of the Private Housing Finance Law, buildings are required to register their rent regulated apartments.',
  },
  {
    title: 'SEC 608',
    description:
      'Under this section of the Private Housing Finance Law, buildings are required to register their rent regulated apartments.',
  },
  {
    title: 'NON-EVICT COOP/CONDO',
    description:
      'A building that has become a cooperative or a condominium without the right to evict any non-purchasing tenants.',
  },
  {
    title: 'EVICT COOP/CONDO',
    description:
      'A building that has become a cooperative or a condominium with the right to evict non-purchasing tenants other than eligible senior citizens or eligible disabled tenants within a specified period of time.',
  },
  {
    title: 'COOP/CONDO PLAN FILE',
    description:
      "A Coop/Condo conversion plan has been accepted for filing by the Attorney General's office.",
  },
  {
    title: 'HOTEL',
    description: `Under rent stabilization, a multiple dwelling which provides all of the following services included in the rent:

                  Maid service, consisting of general house-cleaning at a frequency of at least once a week;
                  
                  Linen service, consisting of providing clean linens at a frequency of at least once a week;
                  
                  Furniture and furnishings, including at a minimum a bed, lamp, storage facilities for clothing, chair and mirror in a bedroom; such furniture to be maintained by the hotel owner in reasonable condition; and
                  
                  Lobby staffed 24 hours a day, seven days a week by at least one employee.`,
  },
]

export const getPageName = (page: string | any) => {
  switch (page) {
    case 'unit':
      return 'unit'
    case 'building':
      return 'building'
    case 'contact':
      return 'owner'
    case 'listings':
      return 'exploreListings'
    default:
      return 'home'
  }
}

export const reviewInfo = [
  {
    title: 'Cleanliness',
    key: 'ratingCleanliness',
    description: '1 (unmanaged) to 5 (well managed)',
    aggKey: 'avgCleanliness',
  },
  {
    title: 'Garbage Management',
    key: 'ratingGarbageManagement',
    description: '1 (poorly managed) to 5 (well organized)',
    aggKey: 'avgGarbageManagement',
  },
  {
    title: 'Heat',
    key: 'ratingHeat',
    description: '1 (faulty) to 5 (working)',
    aggKey: 'avgHeat',
  },
  {
    title: 'Neighbors',
    key: 'ratingNeighbors',
    description: '1 (loud / disrespectful) to 5 (friendly and considerate)',
    aggKey: 'avgNeighbors',
  },
  {
    title: 'Noise Levels',
    key: 'ratingNoiseLevels',
    description: '1 (loud) to 5 (quiet)',
    aggKey: 'avgNoiseLevels',
  },
  {
    title: 'Owner Responsiveness',
    key: 'ratingOwnerResponsiveness',
    description: '1 (slow) to 5 (timely)',
    aggKey: 'avgOwnerResponsiveness',
  },
  {
    title: 'Pest Control',
    key: 'ratingPestControl',
    description: '1 (lots of pests) to 5 (no pests)',
    aggKey: 'avgPestControl',
  },
  {
    title: 'Water Pressure',
    key: 'ratingWaterPressure',
    description: '1 (weak) to 5 (strong)',
    aggKey: 'avgWaterPressure',
  },
]

export const violationHistory = {
  last3YearViolations: {
    a: 8,
    b: 10,
    c: 23,
    i: 3,
  },
  unitViolations: [
    {
      unit: null,
      floor: '1',
      classA: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classB: [],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
    {
      unit: 'L10',
      floor: '1',
      classA: [],
      classB: [],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
    {
      unit: '204',
      floor: '2',
      classA: [],
      classB: [],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
  ],
}

export const violationHistory1 = {
  last3YearViolations: {
    a: 22,
    b: 51,
    c: 29,
    i: 0,
  },
  unitViolations: [
    {
      unit: '45X',
      floor: '45',
      classA: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classB: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
    {
      unit: '40J',
      floor: '40',
      classA: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classB: [],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
    {
      unit: '18M',
      floor: '18',
      classA: [],
      classB: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classC: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classI: [],
    },
  ],
}

export const violationHistory2 = {
  last3YearViolations: {
    a: 2,
    b: 2,
    c: 0,
    i: 0,
  },
  unitViolations: [
    {
      unit: null,
      floor: '10',
      classA: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classB: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classC: [],
      classI: [],
    },
    {
      unit: 'S10',
      floor: '10',
      classA: [],
      classB: [
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
        {
          year: 2024,
          approvedDate: '2024-01-21T00:00:00',
          currentStatus: 'NOV SENT OUT',
          novDescription: '§ 27-2029',
          violationStatus: 'Open',
        },
      ],
      classC: [],
      classI: [],
    },
  ],
}

export const complaintsHistory = {
  topComplaints: {
    'Illegal Parking': 67,
    'Noise - Helicopter': 45,
    'Street Condition': 5,
  },
  complaints: {
    '2021': {
      february: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      may: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      november: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2022': {
      january: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      june: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      november: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2023': {
      april: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      may: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      august: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2024': {
      march: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      october: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      december: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
    },
  },
}

export const complaintsHistory1 = {
  topComplaints: {
    'Illegal Parking': 20,
    'Noise - Helicopter': 25,
    'Broken Parking Meter': 59,
  },
  complaints: {
    '2021': {
      february: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      may: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
    },
    '2022': {
      january: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      june: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      november: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2023': {
      april: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      may: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      june: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2024': {
      march: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      december: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
    },
  },
}

export const complaintsHistory2 = {
  topComplaints: {
    'Illegal Parking': 88,
    'Noise - Helicopter': 12,
    'Street Condition': 50,
  },
  complaints: {
    '2021': {
      february: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      march: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      december: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2022': {
      march: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      september: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      october: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2023': {
      april: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
      may: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      august: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
        {
          status: 'Closed',
          descriptor: 'SPRINKLER',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
      ],
    },
    '2024': {
      june: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
      december: [
        {
          status: 'Closed',
          descriptor: 'SMOKE DETECTOR',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'SAFETY',
        },
        {
          status: 'Closed',
          descriptor: 'NO LIGHTING',
          agency_name: 'Department of Housing Preservation and Development',
          created_date: '2021-12-31T20:21:38.000',
          complaint_type: 'ELECTRIC',
        },
      ],
    },
  },
}

export const rodentData = {
  Passed: {
    title: 'passed',
    description: 'The inspector did not observe any active signs of rat and mouse activity.',
  },
  'Rat Activity': {
    title: 'rat activity',
    description:
      'The Health Department observed active signs of rat and mice activity, or conditions conducive to rodent activity.',
  },
  'Failed for Other R': {
    title: 'failed for other reasons',
    description:
      'The Health Department reported unsanitary signs at the building, such as mice, uncontained garbage, or overgrown vegetation.',
  },
  'Bait applied': {
    title: 'bait applied',
    description:
      'A Health Department pest professional has applied applications, or baits at this building.',
  },
  'Monitoring visit': {
    title: 'monitoring visit',
    description:
      'Follow-up visits from the Health Department to monitor progress from the last failed inspections.',
  },
}

export const rodentBlur = {
  rodents: [
    {
      approvedDate: '2024-09-04T09:10:38.000',
      inspectionDate: '2024-08-28T08:12:35.000',
      inspectionType: 'Initial',
      result: 'Rat Activity',
    },
  ],
}

export const rodentBlur1 = {
  rodents: [
    {
      approvedDate: '2024-09-04T09:10:38.000',
      inspectionDate: '2024-08-28T08:12:35.000',
      inspectionType: 'Initial',
      result: 'Passed',
    },
  ],
}

export const rodentBlur2 = {
  rodents: [
    {
      approvedDate: '2024-09-04T09:10:38.000',
      inspectionDate: '2024-08-28T08:12:35.000',
      inspectionType: 'Initial',
      result: 'Bait applied',
    },
  ],
}

export const bedbugBlur = {
  bedbugs: [
    {
      ofDwellingUnits: 416,
      infestedDwellingUnitCount: 1,
      eradicatedUnitCount: 1,
      reInfestedDwellingUnit: 0,
      filingDate: '2023-04-04T00:00:00.000',
    },
  ],
}

export const bedbugBlur1 = {
  bedbugs: [
    {
      ofDwellingUnits: 0,
      infestedDwellingUnitCount: 0,
      eradicatedUnitCount: 0,
      reInfestedDwellingUnit: 0,
      filingDate: '2023-04-04T00:00:00.000',
    },
  ],
}

export const bedbugBlur2 = {
  bedbugs: [
    {
      ofDwellingUnits: 200,
      infestedDwellingUnitCount: 1,
      eradicatedUnitCount: 0,
      reInfestedDwellingUnit: 0,
      filingDate: '2023-04-04T00:00:00.000',
    },
  ],
}

export const reportRatingsBlur = {
  avgCleanliness: 4.315789473684211,
  avgGarbageManagement: 4.533333333333333,
  avgHeat: 4.526315789473684,
  avgNeighbors: 4.733333333333333,
  avgNoiseLevels: 4.2105263157894735,
  avgOverallRating: 4.355701754385965,
  avgOwnerResponsiveness: 3.736842105263158,
  avgPestControl: 4.421052631578948,
  avgWaterPressure: 4.368421052631579,
  reviewCount: 34,
}

export const reportRatingsNeighborhoodBlur = {
  avgCleanliness: 4.0,
  avgGarbageManagement: 3.9,
  avgHeat: 4.8,
  avgNeighbors: 4.733333333333333,
  avgNoiseLevels: 4.2105263157894735,
  avgOverallRating: 4.0,
  avgOwnerResponsiveness: 3.9,
  avgPestControl: 4.421052631578948,
  avgWaterPressure: 4.368421052631579,
  reviewCount: 34,
  neighborhoodName: 'Financial District',
}

export const reportTenancyBlur = {
  buildingAverageRentIncrease: 8,
  buildingTenancyLength: 13,
  neighborhoodAverageRentIncrease: 13,
  neighborhoodTenancyLength: 4,
}

export const rentValueBlur = {
  rentValue: {
    medianBoroughRent: 4982,
    medianBuildingRent: 3708,
    medianNeighborhoodRent: 5175,
    boroughMediansByBedrooms: {
      '0': 3995,
      '1': 4887,
      '2': 6198,
      '3': 7395,
    },
    buildingMediansByBedrooms: {
      '0': 0,
      '1': 0,
      '2': 3708,
      '3': 0,
    },
    neighborhoodMediansByBedrooms: {
      '0': 4605,
      '1': 5898,
      '2': 5650,
      '3': 16995,
    },
  },
}

export const constructionBlur = {
  constructions: {
    majorConstruction: {
      A1: [
        {
          borough: 'MANHATTAN',
          house__: '116',
        },
      ],
    },
    minorConstruction: {
      A3: [
        {
          borough: 'MANHATTAN',
        },
        {
          borough: 'MANHATTAN',
        },
        {
          borough: 'MANHATTAN',
        },
      ],
      SG: [
        {
          borough: 'MANHATTAN',
        },
        {
          borough: 'MANHATTAN',
        },
      ],
    },
  },
}

export const subscriptionFaqs = [
  {
    title: "What's included in the openigloo subscription?",
    description:
      'openigloo subscribers have full access to reviews and can read building report(s) based on their subscription level.',
  },
  {
    title: "What's included in the Building Report?",
    description: `Building complaint and pricing history broken down by month, the latest rodent inspection reports, existing or upcoming construction projects in the building and area, and more. 
      <a target='_blank' rel="noopener noreferrer" href="https://oi-prod-static.s3.us-east-1.amazonaws.com/rental-report-sample.jpeg" class="text-bluegray-300 underline">Click here to see an example.</a>`,
  },
  {
    title: 'Do I have to subscribe to read landlord and building reviews?',
    description:
      "No. You can submit an anonymous review for any building in the US and you will gain full access to read reviews. <br/><br/> Access is guaranteed for life, as long as your review has been approved and stays published. <br/><br/> If your review is rejected by the openigloo team, please make the necessary edits to your review using our <a href='/community-guidelines' class='text-bluegray-300 underline'>community guidelines<a/> and re-submit for approval. Once the review is approved, you'll gain access.",
  },
  {
    title: 'What are the benefits of being a Reviewer?',
    description:
      "As a Reviewer, you gain full access to openigloo's reviews, building ratings, open city data on open violations and recommendations. You also get a discount on your openigloo subscription.",
  },
  {
    title: 'How do I access reviews after subscribing?',
    description: `Go to <a href='/' class="text-bluegray-300 underline">openigloo.com</a> or openigloo's mobile app. Use the search bar under “Read Reviews” and search for any address in the US. <br/><br/> Once you find your building page, simply scroll down to read violations, owner(s) info, reviews, recommendations and much more!`,
  },
  {
    title: 'How do I access Building Reports after subscribing?',
    description: `Go to <a href='/' class="text-bluegray-300 underline">openigloo.com</a> or openigloo's mobile app. Use the search bar under “Read Reviews” and search for any address in NYC. <br/><br/> Once you find the building page, click on the "Building Report" tab, or scroll down and click on "Get report".`,
  },
  {
    title: 'Any other questions or suggestions?',
    description: `Do not hesitate to reach out. Send us an email to <a href="mailto:info@openigloo.com" class="text-bluegray-300 underline">info@openigloo.com</a>`,
  },
]

export const constructionData = {
  a1: 'This is a permit for major renovations that affect space usage, such as converting commercial space into residential or extending floor levels.',
  nb: 'This is a permit for the construction of a new building or structure.',
  dm: 'This is a permit for full demolition, which means dismantling, razing, or removing all of a building or structure.',
  a2: 'This is a permit for multiple alterations that do not affect space usage, accessibility, or occupancy.',
  a3: 'This is a permit for a single alteration that does not affect space usage, accessibility, or occupancy.',
  sg: 'This is a permit for displays and signage installations attached to a structure or building.',
  pa: 'This is a permit that is mandated for indoor gatherings of 75 people or outdoor gatherings of 200 or more for religious, recreational, educational, political, or social purposes or to consume food or drink.',
}

export const worstLandlords = [2024, 2023, 2022, 2021]

export const googleReviewsData = [
  {
    authorName: 'Molly McAdoo',
    date: '1/24/2025',
    rating: 5,
    text: 'Openigloo is such a special and unique resource! I reached out via Instagram with a question I’ve always wondered the answer to and I got an extremely quick and specific response. So grateful for their kindness and knowledge!!',
  },
  {
    authorName: 'Kheng Boon Pek',
    date: '1/10/2025',
    rating: 5,
    text: 'Useful when searching for an apartment, getting unbiased reviews, as well as seeing building reports e.g. around hazards, noise complaints, and pest history.',
  },
  {
    authorName: 'Billy Jean',
    date: '10/24/2024',
    rating: 5,
    text: "I gave 5 stars to Openigloo. It's one of the best app for apartment rental. It's easy and secure to apply for housing on Openigloo. The apartments and studios listed on Openigloo are very nice, clean and affordable. All my friends love my apartment so I recommended them the real estate agent Izzy. If you looking for a nice and affordable apartment right now, try Openigloo. You'll love it.",
  },
  {
    authorName: 'Sophia Vega-Jones',
    date: '8/1/2024',
    rating: 5,
    text: 'I reached out to Openigloo with a question and I was very surprised on how quickly they answered my question. I truly appreciated that Openigloo wasted no time and gave me the information I so needed to make an informed decision Thank you !!',
  },
  {
    authorName: 'Jessie Charbonneau',
    date: '7/11/2024',
    rating: 5,
    text: 'Such a helpful app and team! Makes the NYC rental market significantly easier to navigate. Cannot recommend enough!',
  },
  {
    authorName: 'Molly Hawks',
    date: '2/19/2024',
    rating: 5,
    text: 'OpenIgloo is what the NYC housing market (and really anywhere) needs. The ability to read reviews and share my housing experience has helped avoid some bad housing situations and hopefully help others avoid my experiences. I would still cross reference HPD to make sure all info is up to date but this is an absolutely AMAZING resource!',
  },
  {
    authorName: 'Rachael Davidson',
    date: '1/30/2024',
    rating: 5,
    text: 'Very clear communication and open to helping any renter in need— no question is too small or too big!',
  },
  {
    authorName: 'Flex',
    date: '1/30/2024',
    rating: 5,
    text: 'It’s SO incredibly hard to find people in the rental space with good hearts and who actually want to help! OpenIgloo has done that for me and calmed my anxieties multiple times for years. Always even helping me with a flurry of DMs on socials to them. Thank you!',
  },
  {
    authorName: 'Alisha N',
    date: '1/21/2024',
    rating: 5,
    text: 'Reliable reference, a lot of community online interaction that chime in and corroborate the experience or advice being given to renters',
  },
  {
    authorName: 'G K',
    date: '1/18/2024',
    rating: 5,
    text: 'I reached out to Openigloo on IG with some questions about the building I am currently living in. They were very fast to respond, and confirmed a lot of questions I had about information I was not sure of. Great resource for people who wanna live in NYC!',
  },
  {
    authorName: 'Dondre Harris',
    date: '10/25/2023',
    rating: 5,
    text: 'Great experience with the Openigloo team! I needed help navigating the terms of my lease and discussing various renting laws and benefits of NYC. The Openigloo team was very helpful. I would recommend giving them a chance with your renting needs as well!',
  },
  {
    authorName: 'Marsha Thomas',
    date: '7/31/2023',
    rating: 5,
    text: 'I used the app to access information regarding available apartments. The app is easy to use & self explanatory. I did run into difficulty, but the team is extremely attentive & acted quickly to streamline the process. I recommend this app to anyone searching for their new home.',
  },
  {
    authorName: 'Lisseth Castillo',
    date: '3/19/2023',
    rating: 5,
    text: 'The app is really easy to use and the process of applying to apartments is fast and efficient. Really recommend it.',
  },
  {
    authorName: 'T O',
    date: '2/28/2023',
    rating: 5,
    text: "One of the best creations known to man kind. NYers understand the importance of getting reviews/feedback from neighbors or previous tenants because the NY slumlords LOVE doing patch work & rebranding the management company. Openigloo has the car fax. Any violations the building may have and even if it doesnt specify which kinds, it gives you the total open cases which you can then look at the 311 website for details (they provide the links). It's amazing! Definitely recommend",
  },
  {
    authorName: 'Dan Chi',
    date: '2/27/2023',
    rating: 5,
    text: 'This is such a great app with awesome resources that helped with my move! They have been so helpful and great to work with! Everyone should check it out!! I can’t thank them enough!',
  },
  {
    authorName: 'C. Willi',
    date: '1/17/2023',
    rating: 5,
    text: 'Really great idea and app. The team is very responsive and open to suggestions to improve the app experience.',
  },
]
