import { icons } from '../../../icons'

export interface OIPillProps {
  type?: OIPillType
  label?: string
  status?: OIPillStatus | ''
  size?: OIPillSize
  icon?: keyof typeof icons | ''
  iconSize?: OIPillIconSize
  iconPositionLeft?: boolean
  style?: string
}

export enum OIPillType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  PRIMARY_DARK = 'primary_dark',
  RECTANGLE = 'rectangle',
}

export enum OIPillStatus {
  PENDING = 'pending',
  DISABLED = 'disabled',
  SUCCESS = 'successful',
  ERROR = 'error',
  IMPORTANT = 'important',
  ATTENTION = 'attention',
  SUCCESSRECTANGLE = 'successrectangle',
  ATTENTIONRECTANGLE = 'attentionrectangle',
}

export enum OIPillSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum OIPillIconSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}
