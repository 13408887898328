// From https://github.com/kirill-konshin/next-redux-wrapper/blob/master/packages/demo-redux-toolkit/store.tsx
import { createWrapper } from 'next-redux-wrapper'
import { persistReducer, persistStore } from 'redux-persist'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

import {
  ApplicationProfileReducer,
  activityReducer,
  buildingReducer,
  contactReducer,
  listingReducer,
  miscReducer,
  newSearchReducer,
  searchListingReducer,
  userReducer,
  userReviewReducer,
} from './reducers'

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem(_key) {
      return Promise.resolve()
    },
  }
}

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()

const persistConfig = {
  key: 'openigloo',
  storage,
}

const persistedActivityReducer = persistReducer(persistConfig, activityReducer)

const listenerMiddleware = createListenerMiddleware()

export const Store = configureStore({
  reducer: {
    currentBuilding: buildingReducer,
    currentUser: userReducer,
    misc: miscReducer,
    currentListing: listingReducer,
    searchListing: searchListingReducer,
    currentUserReview: userReviewReducer,
    currentContact: contactReducer,
    userActivity: persistedActivityReducer,
    applicationProfile: ApplicationProfileReducer,
    newSearch: newSearchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(listenerMiddleware.middleware),
})
export function makeStore() {
  return Store
}

export type AppStore = typeof Store
export type AppState = ReturnType<AppStore['getState']>
export const wrapper = createWrapper<AppStore>(makeStore)
export const persistor = persistStore(Store)
