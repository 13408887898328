import { Buildings } from '../../data'
import { miscSlice } from '../reducers/misc'

export * from './building'
export * from './user'
export * from './misc'
export * from './listing'

export const fetchFeaturedResources = () => async (dispatch) => {
  const resources = await Buildings.getBuildingResources()
  if (resources.length > 1) {
    dispatch(miscSlice.actions.setFeaturedResources(resources))
  }
}
