import { UserReviewState } from '@localtypes/Store'
import { Review, ReviewSteps, Tenancy } from '@openigloo/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: UserReviewState = {
  tenancy: null,
  stage: ReviewSteps.RATING,
  isFetching: false,
  lastStage: ReviewSteps.DEMOG_SUBMIT,
  isAnonymous: false,
  photosToUpload: [],
  isEditMode: false,
}

export const userReviewSlice = createSlice({
  name: 'currentUserReview',
  initialState,
  reducers: {
    setRating(state, action: PayloadAction<{ ratingTypeId: string; ratingValue: number }>) {
      const { ratingTypeId, ratingValue } = action.payload
      const rating = state.tenancy.review.ratings.find((r) => r.ratingType.id === ratingTypeId)
      rating.value = ratingValue
    },
    setReviewBody(state, action: PayloadAction<Review>) {
      state.tenancy.review = action.payload
    },
    setTenancy(state, action: PayloadAction<Tenancy>) {
      state.tenancy = action.payload
    },
    setStage(state, action: PayloadAction<number>) {
      state.stage = action.payload
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload
    },
    setIsAnonymous(state, action: PayloadAction<boolean>) {
      state.isAnonymous = action.payload
    },
    setPhotosToUpload(state, action: PayloadAction<any>) {
      state.photosToUpload = action.payload
    },
    setIsEditMode(state, action: PayloadAction<boolean>) {
      state.isEditMode = action.payload
    },
    resetAll(state) {
      state.tenancy = null
      state.stage = ReviewSteps.RATING
      state.lastStage = ReviewSteps.DEMOG_SUBMIT
      state.isAnonymous = false
      state.photosToUpload = []
    },
  },
})

export default userReviewSlice.reducer
