import { HYDRATE } from 'next-redux-wrapper'

import { NewSearchState } from '@localtypes/Store'
import { createSlice } from '@reduxjs/toolkit'

const initialState: NewSearchState = {
  grpNeighborhoods: null,
  neighborhoods: null,
  trendingNeighborhoods: null,
  searchFilter: {
    neighborhoodIds: [],
    buildingId: null,
    buildingName: null,
  },
  filters: {
    maxRent: null,
    minRent: null,
    isNoFee: null,
    numBedrooms: [],
    minNumBathrooms: null,
    minExpectedMoveInDate: null,
    maxExpectedMoveInDate: null,
    isUnitPetsAllowed: null,
    isUnitWasherDryer: null,
    isUnitPrivateOutdoor: null,
    isUnitDishwasher: null,
    isBuildingOutdoor: null,
    isBuildingGym: null,
    isBuildingPool: null,
    isBuildingParking: null,
    isBuildingDoorman: null,
    isBuildingLaundryRoom: null,
    isBuildingElevator: null,
    isBuildingRentStabilized: null,
    hasNoBuildingOneYearBedbugHistory: null,
    hasNoBuildingEvictionHistory: null,
    hasNoBuildingPendingLitigation: null,
    hasNoBuildingOpenViolations: null,
    hasBuildingGoodCauseEviction: null,
    hasBuildingMinFourRating: null,
  },
  profileFilter: {
    householdIncome: null,
    hasGuarantor: null,
    creditScoreRange: null,
  },
  stopLoading: false,
}

export const newSearchSlice = createSlice({
  name: 'currentNewSearch',
  initialState,
  reducers: {
    setGrpNeighborhoods: (state, action) => {
      state.grpNeighborhoods = action.payload
    },
    setNeighborhoods: (state, action) => {
      state.neighborhoods = action.payload
    },
    setTrendingNeighborhoods: (state, action) => {
      state.trendingNeighborhoods = action.payload
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = { ...state.searchFilter, ...action.payload }
      localStorage.setItem('sfilter', JSON.stringify(state.searchFilter))
    },
    setProfileFilter: (state, action) => {
      state.profileFilter = { ...state.profileFilter, ...action.payload }
      localStorage.setItem('pfilter', JSON.stringify(state.profileFilter))
    },
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
      localStorage.setItem('nfilter', JSON.stringify(state.filters))
    },
    resetFilter: (state) => {
      state.filters = {
        ...initialState.filters,
      }
      localStorage.setItem('nfilter', JSON.stringify(state.filters))
    },
    setStopLoading: (state, action) => {
      state.stopLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.currentNewSearch,
      }
    })
  },
})

export default newSearchSlice.reducer
