import { HYDRATE } from 'next-redux-wrapper'

import { ApplicationProfileState } from '@localtypes/Store'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ApplicationProfileState = {
  applicationListing: null,
}

export const applicationProfileSlice = createSlice({
  name: 'applicationProfile',
  initialState,
  reducers: {
    setApplicationListing: (state, action) => {
      state.applicationListing = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.applicationProfile,
      }
    })
  },
})

export default applicationProfileSlice.reducer
