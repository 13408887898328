import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { isMobile } from 'react-device-detect'
import { PageMetaDescriptions } from 'src/common/Constants'

import { requireLogin as requireLoginAction } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'

export interface LayoutProps {
  children?: JSX.Element
  title?: string
  url?: string
  cover?: string
  description?: string
  withBrand?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  headerHeight?: string
  showBottomNav?: boolean
  requireLogin?: boolean
  showMobileSearchBar?: boolean
  authDismissURL?: string
  canonicalURL?: string
  showMobileTitle?: boolean
  hideMobileHeader?: boolean
  hideMobileFooter?: boolean
}

const MobileLayout = dynamic(() => import('@components/layouts/MobileLayout'), {
  ssr: true,
})

const DesktopLayout = dynamic(() => import('@components/layouts/DesktopLayout'), {
  ssr: true,
})

const Layout = ({
  children,
  title,
  url,
  cover = '/logo.png',
  description = PageMetaDescriptions.home,
  withBrand = true,
  requireLogin, // TODO: remove
  canonicalURL = null,
  showMobileTitle = false,
  hideMobileHeader = false,
  hideMobileFooter = false,
}: LayoutProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.currentUser)
  const router = useRouter()
  const titleText = withBrand ? `${title} | openigloo` : title

  const [showCookieConsent, _setShowCookieConsent] = useState(false)

  useEffect(() => {
    if (requireLogin && isLoggedIn === false) {
      requireLoginAction(null, () => router.push('/'))
    }
  }, [requireLogin, isLoggedIn])

  const baseUrl = 'https://www.openigloo.com'

  return (
    <>
      <Head>
        <title>{titleText}</title>
        {(process.env.STAGING == '1' ||
          router?.query?.buildingReport ||
          router?.asPath?.includes('boston')) && <meta name="robots" content="noindex, nofollow" />}
        <meta name="description" content={description} />
        <meta property="og:title" content={titleText} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={titleText} />
        <meta property="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={cover} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={baseUrl + cover} />
        <meta name="apple-itunes-app" content="app-id=1521697583" />
        <meta name="google-play-app" content="app-id=com.openigloo" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <meta name="msapplication-TileColor" content="#070052" />
        <meta name="theme-color" content="#ffffff" />
        {canonicalURL !== null ? <link rel="canonical" href={canonicalURL} /> : null}
      </Head>

      {isMobile ? (
        <MobileLayout
          showMobileTitle={showMobileTitle}
          title={title}
          hideMobileHeader={hideMobileHeader}
          hideMobileFooter={hideMobileFooter}
        >
          {children}
        </MobileLayout>
      ) : (
        <DesktopLayout>{children}</DesktopLayout>
      )}

      {showCookieConsent && (
        <CookieConsent
          style={{ background: '#fff', color: '#000' }}
          buttonStyle={null}
          containerClasses="flex flex-row flex-wrap justify-center items-center h-32 sm:h-16 w-full mx-auto fixed bottom-0 left-0 z-50 p-2"
          buttonClasses="text-white bg-blue-300 py-2 px-4 rounded-3xl text-center cursor-pointer place-self-center md:place-self-end mx-2"
          disableButtonStyles
          disableStyles
        >
          We use cookies to enhance your experience. By using our website, you agree to our{' '}
          <a href="/privacy-policy" className="underline">
            privacy policy
          </a>
          .
        </CookieConsent>
      )}
    </>
  )
}

export default Layout
