import { REHYDRATE } from 'redux-persist'

import { Popup, UserActivityState } from '@localtypes/Store'
import { ListingInterestBody, OpenListing } from '@openigloo/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: UserActivityState = {
  visitedListings: [],
  lastListingInterestBody: {
    name: '',
    email: '',
    phone: '',
    message: '',
    income: null,
    creditRange: null,
    occupants: '',
    hasPets: false,
  },
  showShareOptions: false,
  referralLink: 'https://link.openigloo.com/web-share',
  visitedPagesCount: 0,
  referralPopup: {
    name: 'referral',
    shownCount: 0,
    converted: false,
    visible: false,
  },
  showMobileNavMenuItems: false,
  alignMenuNavWithModalTitle: false,
}

export const activitySlice = createSlice({
  name: 'currentBuilding',
  initialState,
  reducers: {
    addVisitedListing(state, action: PayloadAction<OpenListing>) {
      if (!state.visitedListings.map((l) => l.id).includes(action.payload.id)) {
        state.visitedListings.push(action.payload)
      }
    },
    setVisitedListings(state, action: PayloadAction<OpenListing[]>) {
      state.visitedListings = action.payload
    },
    setLastListingInterestBody(state, action: PayloadAction<ListingInterestBody>) {
      state.lastListingInterestBody = action.payload
    },
    setShowShareOptions(state, action) {
      state.showShareOptions = action.payload
    },
    setReferralLink(state, action) {
      state.referralLink = action.payload
    },
    resetReferralLink(state) {
      state.referralLink = initialState.referralLink
    },
    setVisitedPagesCount(state, action) {
      state.visitedPagesCount = action.payload
    },
    setReferralPopup(state, action: PayloadAction<Popup>) {
      state.referralPopup = action.payload
    },
    setShowMobileNavMenuItems(state, action: PayloadAction<boolean>) {
      state.showMobileNavMenuItems = action.payload
    },
    setAlignMenuNavWithModalTitle(state, action: PayloadAction<boolean>) {
      state.alignMenuNavWithModalTitle = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, () => {
      //TODO: do we need to reconcile something here?
    })
  },
})

export default activitySlice.reducer
