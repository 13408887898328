import { useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { segment } from 'src/lib/Segments'

import OIButton from '@components/UI/atoms/OIButton'
import OIInput from '@components/UI/atoms/OIInput'
import OIModal from '@components/UI/organisms/OIModal'
import RadioButton from '@components/review/RadioButton'
import SectionSubheader from '@components/section/SectionSubheader'
import { editNewProfile, setAuthRequired } from '@redux/actions/user'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { userSlice } from '@redux/reducers/user'
import { formatPhoneNumber } from '@utility/Utilities'

export interface ModalProps {
  onClose: () => void
}

function CompleteProfile({ onClose }: ModalProps) {
  const { profile, token } = useAppSelector((state) => state.currentUser)

  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [phoneError, setPhoneError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [consent, setConsent] = useState(true)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    setFirstName(profile?.firstName ?? null)
    setLastName(profile?.lastName ?? null)
    setPhone(profile?.phone ?? null)
  }, [])

  const CustomHeader = () => {
    return (
      <div className="flex w-full items-center justify-between border-b bg-light-10 px-8 py-4 text-xl text-dark-900 md:justify-start md:py-6">
        <div className="flex-1 flex flex-col">
          <span className="flex-1 text-center md:text-left">Welcome to openigloo</span>
        </div>
        <GrClose
          className="cursor-pointer"
          color="#6764A1"
          size={16}
          onClick={() => {
            segment?.['listings_filter_all_close']?.()
            onClose()
          }}
        />
      </div>
    )
  }

  const completeProfile = async () => {
    const authToken = token

    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
    }

    try {
      await dispatch(editNewProfile(authToken, data))
      dispatch(setAuthRequired(false))
      if (consent) {
        dispatch(userSlice.actions.setConsentEnabled(consent))
        dispatch(userSlice.actions.setTriggerConsent(true))
      }
      onClose()
    } catch (e) {
      console.log('Something went wrong!')
    }
  }

  return (
    <OIModal
      onClose={() => {
        onClose()
      }}
      customModalStyle="!max-w-md md:h-auto"
      customHeader={<CustomHeader />}
      useStandardHeader={false}
      closeOnOutsideClick={true}
      closeOnEscape={true}
    >
      <div className="slide-left p-8">
        <div className="pb-4 text-xl font-semibold text-dark-900">Tell us about yourself</div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="space-y-1">
            <SectionSubheader style="!text-sm">First name</SectionSubheader>
            <OIInput
              autoFocus
              autoComplete="off"
              placeholder="First"
              className="text-sm"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstName && !firstName.trim().length && (
              <div className="-mt-3 text-xs text-red-400">Enter first name</div>
            )}
          </div>
          <div className="space-y-1">
            <SectionSubheader style="!text-sm">Last name</SectionSubheader>
            <OIInput
              autoComplete="off"
              placeholder="Last"
              className="text-sm"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastName && !lastName.trim().length && (
              <div className="-mt-3 text-xs text-red-400">Enter last name</div>
            )}
          </div>

          <div className="col-span-2 space-y-1 ">
            <SectionSubheader style="!text-sm">Phone</SectionSubheader>
            <OIInput
              type={'tel'}
              placeholder="Your phone number"
              className="text-sm"
              value={phone}
              invalid={phone && !(phone.replace(/\D/g, '').length === 10)}
              onChange={(e) => {
                setPhone(formatPhoneNumber(e.target.value))
                // setPhoneError(false)
              }}
              onBlur={() => {
                if (phone && !(phone.replace(/\D/g, '').length === 10)) {
                  setPhoneError(true)
                } else {
                  // setPhoneError(false)
                }
              }}
            />
            {phoneError && !(phone.replace(/\D/g, '').length === 10) && (
              <div className="-mt-3 text-xs text-red-400">Please enter a valid phone number</div>
            )}
          </div>
        </div>
        <div className="pt-4">
          <RadioButton
            selectedValue={consent}
            customStyle={'!items-start'}
            options={{
              'Sign me up for text alerts! Get the latest listings and updates from openigloo. Reply STOP to unsubscribe.':
                true,
            }}
            onSelect={async () => {
              if (consent) {
                segment?.unit_contact_agent_sms_consent_uncheck()
                setConsent(false)
              } else {
                segment?.unit_contact_agent_sms_consent_check()
                setConsent(true)
              }
            }}
          />
        </div>

        <div className="my-6 flex flex-col space-y-1">
          <SectionSubheader style="!text-sm">Your email</SectionSubheader>
          <OIInput
            className="text-sm"
            onChange={() => null}
            placeholder="Your email"
            disabled={!!profile.email}
            value={profile.email}
          />
        </div>

        <div className="mb-6 -mt-1 space-y-2 text-xs text-dark-500">
          <p>Make sure your name matches with your Government ID</p>
          <p>
            This is <b className="font-semibold">NOT</b> shared on any reviews but we need this to
            help verify your identity if you apply or inquire about any apartments.
          </p>
        </div>

        <OIButton
          onClick={() => {
            setIsSubmitting(true)
            completeProfile()
          }}
          isLoading={isSubmitting}
          disabled={
            !firstName?.trim().length ||
            !lastName?.trim().length ||
            !(phone?.replace(/\D/g, '')?.length === 10) ||
            isSubmitting
          }
          style="!w-full"
          label="Complete"
        />
      </div>
    </OIModal>
  )
}

export default CompleteProfile
