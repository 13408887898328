import { getNewSimilarListings, getSavedInterests, getUnitRetrieve } from 'src/data/newListings'

import { ListingInterest } from '@localtypes/Store'

import { listingSlice } from '../reducers/listing'

export const setCurrentListingAndBuilding = (id) => async (dispatch) => {
  const listing = await getUnitRetrieve(id)
  const similarListings = await getNewSimilarListings(id)
  dispatch(listingSlice.actions.setListing(listing))
  dispatch(listingSlice.actions.setSimilarListings(similarListings))
}

export const setListingInterestBody = (body: ListingInterest) => (dispatch) => {
  dispatch(listingSlice.actions.setListingInterestBody(body))
}

export const updateListingInterestBody = (token: string) => async (dispatch) => {
  const data = await getSavedInterests(token)
  if (data) {
    dispatch(setListingInterestBody(data))
  }
}
